<template>
  <ev-remote-select
      ref="selector"
      :label-key="labelField"
      :api-url="getApiUrl"
      :value="getValue"
      @input="handler"
      :single="!multiple"
      :debounced="500"
      focused
      tableable
  >
    <template v-slot:option="prop">
      <td class="p-2">{{ prop.item.mb_id }}</td>
      <td class="p-2">{{ prop.item.mb_level }}</td>
      <td class="p-2">{{ prop.item.mb_name }}</td>
      <td class="p-2">{{ prop.item.mb_hp }}</td>
    </template>
  </ev-remote-select>
</template>

<script>
import EvRemoteSelect from "../components/EvRemoteSelect";

export default {
  name: "EvCustomerSelector",
  components: {EvRemoteSelect},
  data() {
    return {
      mutate: {},
      selector: null
    }
  },
  watch:{
    value:{
      immediate: true,
      handler(val){
        this.mutate = val;
      }
    }
  },
  props: {
    value: null,
    valueField: {
      default: 'mb_no'
    },
    labelField: {
      default: 'mb_name'
    },
    searchField: {
      default: 'mb_name'
    },
    multiple: {
      type: Boolean
    }
  },
  methods: {
    handler(val) {
      this.mutate = val;
      this.$emit('selected', val);
      if (!val) {
        this.$emit('input', null);
        return;
      }

      if (!this.multiple) {
        this.$emit('input', val[this.valueField]);
        return;
      }
      let data = val.map((item) => {
        let temp = {};
        temp[this.valueField] = item[this.valueField];
        temp[this.labelField] = item[this.labelField];
        return temp;
      });
      this.$emit('input', data);
    },
    clear() {
      this.selector.clear()
    }
  },
  computed: {
    getValue() {

      if (this.mutate && this.mutate.hasOwnProperty(this.valueField)) {
        return this.mutate;
      }

      return this.value;
    },
    getApiUrl() {
      return `/api/store/customer?perPage=100&${this.searchField}=`;
    }
  },
  mounted() {
    this.selector = this.$refs.selector
  }
}
</script>

<style scoped>

</style>
