<template>
  <div>
    <ev-form class="my-4 p-2 bg-white rounded"
        v-model="query" :errors="errors">
      <ev-form-field class="w-1/4" field="it_name" label="상품명"></ev-form-field>
      <ev-form-field class="w-1/4" field="op_id" label="단위">
        <template v-slot="{value,handler}">
          <ev-price-selector value-key="op_id" :value="value" @input="handler"></ev-price-selector>
        </template>
      </ev-form-field>
      <ev-form-field class="w-1/4" field="memo" label="메모"></ev-form-field>
      <ev-button @click="getItems">검색</ev-button>
    </ev-form>
    <grid-template
        v-model="items"
        :errors="errors"
        :field-labels="{
          created_at: '등록일시',
          it_id : '상품번호',
          it_name: '상품명',
          unit: '단위',
          in_qty: '입고수량',
          out_qty: '출고수량',
          type: '유형',
          memo: '메모'
        }"
        cell-type="value"
        pagination
    >
    </grid-template>
    <div class="my-4">
      <el-button type="primary" @click="()=>{ openDialog = !openDialog }">새로 등록하기</el-button>
    </div>
    <el-dialog :visible.sync="openDialog">
      <inventory-form @created="createdItem"></inventory-form>
    </el-dialog>
  </div>
</template>

<script>
import AxiosHelper from "@/evans/plugins/AxiosHelper";
import GridTemplate from "@/evans/templates/GridTemplate";
import InventoryForm from "@/views/Inventory/InventoryForm";
import EvPriceSelector from "@/evans/controls/EvPriceSelector";

export default {
  name: "InventoryList",
  components: {EvPriceSelector, InventoryForm, GridTemplate},
  mixins:[
      AxiosHelper
  ],
  data(){
    return{
      uri : '/api/store/me/inventories',
      openDialog: false,
      query:{
        sort:'id',
        order:'desc'
      }
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    createdItem() {
      this.openDialog = false;
      this.getItems();
    }
  }
}
</script>

<style scoped>

</style>
