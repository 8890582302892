import { render, staticRenderFns } from "./InventoryList.vue?vue&type=template&id=151b8915&scoped=true&"
import script from "./InventoryList.vue?vue&type=script&lang=js&"
export * from "./InventoryList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151b8915",
  null
  
)

export default component.exports