<template>
  <div>

    <PurchaseSearchForm v-if="!fixedQuery" :parent="this"/>

    <table class="w-full mt-2 border-b bg-white">
      <thead class="bg-gray-900 text-white">
      <tr>
        <th class="w-2/12">발주번호</th>
        <th class="w-1/12">발주유형</th>
        <th class="w-2/12">주문시간</th>
        <th class="w-1/12">주문상태</th>
        <th class="w-6/12" style="">메모</th>
      </tr>
      </thead>
      <tbody class="">
      <template v-for="(order, index) in items">
        <tr class="border-t border-gray-400 h-12">
          <td class="text-center">
            <router-link :to="{name: 'PurchaseDetail', params:{purchaseId: order.od_id}}"
                         class="text-blue-600 cursor-pointer hover:underline"
            >{{ order.od_id }}
            </router-link>
          </td>
          <td>
            <el-tag :type=" (order.od_mode === '4') ? 'dark': 'plain' ">
              {{ (order.od_mode === '4') ? '매장발주' : '본사발송' }}
            </el-tag>
          </td>
          <td>{{ order.od_time }}</td>
          <td class="text-center">
            <order-item-status-tag :status="order.od_status"></order-item-status-tag>
          </td>
          <td>{{ order.od_comment }}</td>
        </tr>
      </template>
      <tr v-if="items.length === 0">
        <td colspan="10"
            class="p-8 w-full text-center text-2xl text-gray-500"
        >
          검색결과가 없습니다.
        </td>
      </tr>
      </tbody>
    </table>

    <ev-pagination :total="total"
                   :per-page="perPage"
                   :scope="10"
                   :value="page"
                   @input="setPage"
    />

  </div>
</template>

<script>
import AxiosHelper from "@/evans/plugins/AxiosHelper";
import OrderItemStatusTag from "@/controls/purchase/OrderItemStatusTag";
import PurchaseSearchForm from "@/views/Purchase/PurchaseSearchForm";

export default {
  name: "PurchaseList",
  components: {PurchaseSearchForm, OrderItemStatusTag},
  mixins: [AxiosHelper],
  props: {
    fixedQuery: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      uri: '/api/store/me/purchases',
      primaryKey: 'od_id',
      query:{
        perPage : 20
      }
    }
  },
  methods: {
    setDefaultQuery() {

      if (!this.query['page']) {
        this.$set(this.query, 'page', 1);
      }
      if(this.fixedQuery) {
        this.query = Object.assign(this.query, this.fixedQuery);
      }
    }
  },
  mounted() {
    this.query.mb_id = this.$store.getters["user/user"].mb_id
    this.query.sort = 'od_time';
    this.query.order = 'desc';
    this.getItems();
  }
}
</script>

