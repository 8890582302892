<template>
  <treeselect ref="treeSelect"
              class="border"
              :value="value"
              @input="updateCategory"
              :multiple="false"
              :options="categoryTree"
              placeholder="카테고리 선택"
  />
</template>

<script>
  import axios from "../axiosConfig";

  function category(categories){
    if(typeof categories ==='undefined') return []

    return categories.map(cat => {
      let returnValue = {
        id: cat.ca_id,
        label: cat.ca_name
      }
      let children = category(cat.children)
      if(children.length > 0){
        returnValue.children = children
      }
      return returnValue
    })
  }

  /**
   * 카테고리 셀렉터
   * Component:: https://vue-treeselect.js.org/
   */
  export default {
    name: "categorySelector",
    model: {
      prop: 'value',
      event: 'selected',
    },
    props: {
      value: null,
    },
    data(){
      return {
        // categorySelected: null,
        categories: []
      }
    },
    computed: {
      categoryTree(){
        return category(this.categories)
      }
    },
    methods: {
      updateCategory(value){
        this.$emit('selected', value)
      }
    },
    created() {
      axios.get('/api/v1/category-simple')
        .then(res => {
          this.categories = res.data.data
        })
    },
    mounted() {
      this.$emit('selected', this.value)
    }
    // watch: {
    //   categorySelected(selected){
    //
    //     if(selected === null){
    //       this.$refs.treeSelect.value = null
    //     } else {
    //       this.$emit('selected', selected)
    //     }
    //   }
    // }
  }
</script>

<style>
  .vue-treeselect__control{
    border: none;
  }
</style>