<template>
    <button ref="btn" type="button"
            style="min-height: 40px"
            class="text-13 lg:text-15 py-2 px-2 transition-ease is-activated focus:outline-none focus:gray-shadow relative"
            :class="[getClass, disabledClass]"
            :disabled="isDisabled === true"
            @click="clickHandler"
    >
      <slot></slot>
      <div v-if="isLoading"
           class="loading-wrapper absolute h-full w-full top-0 left-0 bottom-0 right-0 flex items-center justify-center"
      >
        <ElSpinner :radius="60" />
      </div>
    </button>
</template>
<script>
    export default {
        name: 'EvButton',
        props: {
              type: {
                  type: String,
                  default: 'primary'
              },
              disabled: {
                  type: Boolean,
                  default: false
              },
              loading: {
                  type: Boolean,
                  default: false
              },
              timeoutSecond: {
                  type: Number,
                  default: 500
              },
              clickTimeout: {
                  type: Boolean,
                  default: false
              }
        },
        data(){
            return {
                defaultClass : [
                    'py-2',
                    'px-2',
                    'transition-ease',
                    'is-activated',
                    'focus:outline-none',
                    'focus:gray-shadow',
                ],
                typeClass : {
                    primary : [
                        'bg-blue-400',
                        'border-blue-400',
                        'text-white',
                    ],
                    basic : [
                        'border',
                        'border-blue-200',
                        'bg-white',
                        'text-blue-400'
                    ],
                    secondary : [
                        'bg-blue-500',
                        'border-blue-500',
                        'focus:bg-blue-400',
                        'text-white',
                    ],
                    warning : [
                        'bg-orange-500',
                        'focus:bg-orange-400',
                        'text-white',
                    ],
                    danger : [
                        'bg-red-500',
                        'focus:bg-red-400',
                        'text-white',
                    ],
                    plain : [
                        'bg-white-400',
                        'hover:text-gray-700',
                        'focus:bg-gray-400',
                        'text-gray-600',
                    ],
                    disabled : [
                        'opacity-50',
                        'cursor-not-allowed'
                    ],
                },
                isLoading : false
            }
        },
        methods: {
            clickHandler(e) {
                if(this.isLoading === true) {
                    // 로딩중이라면, 클릭을 무시
                    return;
                } else {
                    // clickTimeout 설정이 되어 있다면, 타임아웃 시작
                    if(this.clickTimeout === true) {
                        this.setClickTimeout();
                    }
                }

                if(this.$listeners.click){
                    return this.$listeners.click(e);
                }
            },
            setClickTimeout(){
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                },this.timeoutSecond)
            }
        },
        computed: {
            getClass() {
                return this.typeClass[this.type];
            },
            disabledClass() {
              return this.isDisabled ? ['opacity-50', 'cursor-not-allowed', 'text-white'] : null
            },
            isDisabled(){
                return this.disabled === true || this.isLoading === true
            },
        },
        watch: {
            loading(loading){
                this.isLoading = loading
            }
        }
    }
</script>
<style scoped lang="scss">
    .focus\:gray-shadow:focus{
        box-shadow: 0 0 0 3px #60606036
    }
    .is-activated:active{
        transform: translateY(2px);
    }
</style>
