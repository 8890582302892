var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"w-full bg-white"},[_vm._m(0),_vm._m(1),_c('tbody',[_vm._l((_vm.fixedNoticeData),function(fixednotice){return [_c('tr',{staticClass:"h-16 font-extrabold",class:{
                        'border-b hover:bg-gray-200': !_vm.toggleList.includes('fixed_' + fixednotice.wr_id),
                        'bg-gray-100':_vm.toggleList.includes('fixed_' + fixednotice.wr_id)
                    },on:{"click":function($event){return _vm.showContents('fixed_' + fixednotice.wr_id)}}},[_c('td',[_vm._v(" "+_vm._s(fixednotice.wr_id)+" ")]),_c('td',{staticClass:"text-left hover:underline cursor-pointer"},[_vm._v(" "+_vm._s(fixednotice.wr_subject)+" ")]),_c('td',[_vm._v(" "+_vm._s(fixednotice.mb_id)+" ")]),_c('td',[_vm._v(" "+_vm._s(fixednotice.wr_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(fixednotice.wr_datetime)+" ")]),_c('td',[_vm._v(" "+_vm._s(fixednotice.wr_hit)+" ")])]),(_vm.toggleList.includes('fixed_' + fixednotice.wr_id))?_c('tr',{staticClass:"h-16 text-left border-b",class:{
                        'bg-gray-100':_vm.toggleList.includes('fixed_' + fixednotice.wr_id)
                    }},[_c('td',{staticClass:"p-4",attrs:{"colspan":"6"},domProps:{"innerHTML":_vm._s(fixednotice.wr_content)}})]):_vm._e()]}),_vm._l((_vm.noticeData),function(notice){return [_c('tr',{staticClass:"h-16",class:{
                        'border-b hover:bg-gray-200': !_vm.toggleList.includes(notice.wr_id),
                        'bg-gray-100': _vm.toggleList.includes(notice.wr_id)
                    },on:{"click":function($event){return _vm.showContents(notice.wr_id)}}},[_c('td',[_vm._v(" "+_vm._s(notice.wr_id)+" ")]),_c('td',{staticClass:"text-left hover:underline cursor-pointer"},[_vm._v(" "+_vm._s(notice.wr_subject)+" ")]),_c('td',[_vm._v(" "+_vm._s(notice.mb_id)+" ")]),_c('td',[_vm._v(" "+_vm._s(notice.wr_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(notice.wr_datetime)+" ")]),_c('td')]),(_vm.toggleList.includes(notice.wr_id))?_c('tr',{staticClass:"h-16 text-left border-b",class:{
                        'bg-gray-100': _vm.toggleList.includes(notice.wr_id)
                    }},[_c('td',{staticClass:"p-4",attrs:{"colspan":"6"},domProps:{"innerHTML":_vm._s(notice.wr_content)}})]):_vm._e()]}),(_vm.noticeData.length === 0)?_c('tr',[_c('td',{staticClass:"p-8 w-full text-center text-2xl text-gray-500",attrs:{"colspan":"6"}},[_vm._v(" 검색결과가 없습니다. ")])]):_vm._e()],2)]),_c('ev-pagination',{attrs:{"total":_vm.pageInfo.total,"per-page":_vm.pageInfo.per_page,"scope":7,"value":_vm.pageInfo.current_page},on:{"input":_vm.setPage}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"50px"}}),_c('col'),_c('col',{attrs:{"width":"100px"}}),_c('col',{attrs:{"width":"100px"}}),_c('col',{attrs:{"width":"90px"}}),_c('col',{attrs:{"width":"60px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray-900 text-white"},[_c('tr',{staticClass:"h-12"},[_c('th',[_vm._v("번호")]),_c('th',[_vm._v("제목")]),_c('th',[_vm._v("아이디")]),_c('th',[_vm._v("고객명")]),_c('th',[_vm._v("날짜")]),_c('th',[_vm._v("조회수")])])])
}]

export { render, staticRenderFns }