export function getSerializedQuery(query) {
    if(typeof query !== 'object' || !query){
        return '';
    }
    return Object.keys(query).map((key) => {
        if (!query[key]) {
            return null;
        }
        if (typeof query[key] === 'object' && query[key]) {
            return this.parseQuery(query[key], key);
        }

        return key + '=' + query[key];

    }).filter(item => {
        return item;
    }).join('&');
}