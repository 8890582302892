import axios from "../../axiosConfig";
import {messenger} from "@/evans/plugins/EvToast";
import {MessageBox} from 'element-ui';
import moment from "moment";

const orderModel = {
    od_send_cost: 0,
    od_cash_type: null,
    od_cash_num: null,
    od_dc_amount: 0,
    od_dc_memo: '',
    od_receipt_bank: 0,
    od_receipt_card: 0,
    od_comment: '',
}

/**
 * 판매(Sales) 페이지의 장바구니(Cart) Store
 * TODO:: 판매(등록) / 주문수정(수정) store 분리 고려 필요
 */
export default {
    namespaced: true,
    state: {
        mode: 'new',
        uid: null,
        items: [],
        coupon: null,
        member: null,
        wholesale: false,
        order: JSON.parse(JSON.stringify(orderModel)),
        prevOrder: null,
        pendingList: [], // 보류 장바구니 목록
        discounts: []
    },
    getters: {
        cart: state => {
            return state.items
        },
        member: state => {
            return state.member || null
        },
        mode: state => {
            return state.mode
        },
        order: state => {
            return state.order
        },
        totalDiscountAmount(state, getters) {
            let total = state.items.reduce((amount, crr) => {
                return amount + (crr.discount_amount * crr.ct_qty)
            }, 0);

            return total + getters.orderSaleDiscountAmount;
        },
        orderSaleDiscountAmount: state => {
            let discount_amount = 0
            if(!state.discounts) return 0
            Object.keys(state.discounts).forEach(key => {
                state.discounts[key].map(dis => {
                    if(dis?.coupon_id && dis?.coupon_id === state.coupon?.id) {
                        let amount = ( dis?.pre_amount ) ? dis?.pre_amount : 0
                        discount_amount += amount * dis.qty
                    }
                })
            })
            return discount_amount;
        },
        couponDiscount: state => {
            if (state.mode === 'new') {
                let discount_amount = 0
                if(!state.discounts) return 0
                Object.keys(state.discounts).forEach(key => {
                    state.discounts[key].map(dis => {
                        if(dis?.coupon_id && dis?.coupon_id === state.coupon?.id) {
                            let amount = ( dis?.pre_amount ) ? dis?.pre_amount : dis?.amount
                            discount_amount += amount * dis.qty
                        }
                    })
                })
                return discount_amount;
            } else if (state.mode === 'edit') {
                if (state.order.coupon_issue !== null) {
                    return state.order.coupon_issue.discounted_amount
                } else {
                    return 0
                }
            }
        },
        totalDiscountedAmount: state => {
            return state.items.reduce((amount, crr) => {
                if (state.mode === 'edit') {
                    return amount + (crr.ct_amount * crr.ct_qty)
                }
                return amount + ((crr.ct_amount - crr.discount_amount) * crr.ct_qty)
            }, 0)
        },
        totalAmount: state => {
            return state.items.reduce((amount, crr) => {
                return amount + (crr.ct_amount * crr.ct_qty)
            }, 0)
        },
        canceledAmount: state => {
            return state.items.reduce((canceled, crr) => {
                if (crr.ct_status === "취소" || crr.ct_status === "반품") {
                    return canceled + (crr.ct_amount * crr.ct_qty)
                } else {
                    return canceled
                }
            }, 0)
        },
        activatedCoupon: state => {
            return state.coupon
        },
        prevOrder: state => {
            return state.prevOrder
        },
        pendingList: state => {
            return state.pendingList
        }
    },
    mutations: {
        setMode(state, payload) {
            let input = ['new', 'edit'].find(mode => mode === payload)
            if (typeof input === 'undefined') {
                throw Error('판매모드는 신규(new), 수정(edit)만 가능합니다.')
            }
            state.mode = payload
        },
        // (판매 모드 전용) 주문 정보를 세팅
        setOrder(state, payload) {
            state.mode = 'edit'
            state.order = payload
        },
        setCart(state, payload) {
            state.items = payload
        },
        setDiscounts(state, payload) {
            state.discounts = payload
        },
        clearCart(state) {
            state.mode = 'new'
            state.items = []
            state.member = null
            state.wholesale = false
            state.coupon = null
            state.order = JSON.parse(JSON.stringify(orderModel))

            this.order = null
        },
        setMember(state, payload) {
            state.member = payload
            state.order.member = payload

            // 도매 / 소매 가격 세팅 구분
            if (state.member !== null) {
                state.wholesale = payload.mb_level >= 5;
            }
        },
        setCoupon(state, payload) {
            state.coupon = payload
        },
        clearCoupon(state) {
            state.coupon = null
        },
        setOrderField(state, payload) {

            state.order[payload.field] = payload.value
        },
        // 이전 주문 정보 설정
        setPrevOrder(state, payload) {
            state.prevOrder = payload
        },
        // 장바구니 보류 추가
        addPendingCart(state, payload) {
            state.pendingList.push(payload)
        },
        // 보류 장바구니 삭제
        removePendingCart(state, payload) {
            let index = state.pendingList.findIndex(i => i.on_uid === payload)
            state.pendingList.splice(index, 1)
        }
    },
    actions: {
        /**
         * 주문정보 조회
         * @param commit
         * @param payload
         * @return {Promise<AxiosResponse<any>>}
         */
        async getOrder({commit}, payload) {
            return await axios.get('/api/store/order/' + payload)
                .then(res => {
                    commit('setOrder', res.data)
                    commit('setMember', res.data.member)
                    commit('setCart', res.data.items)
                    if (res.data.coupon_issue !== null) {
                        commit('setCoupon', res.data.coupon_issue.coupon)
                    }

                    return Promise.resolve(res)
                })
        },
        /**
         * 세션의 장바구니 목록을 가져온다.
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<void>}
         */
        async getMyCart({commit, dispatch, state}) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.get('/api/store/my-cart')
                    .then(res => {
                        commit('setCart', res.data.data)
                        commit('setDiscounts', res.data.discounts)
                        // 장바구니 세션 번호(on_uid)가 없거나, 스토어에 저장된 것과 다르다면 갱신
                        if (res.data.data.length > 0) {
                            if (state.uid === null || state.uid !== res.data.data[0].on_uid) {
                                state.uid = res.data.data[0].on_uid

                            }
                        } else {
                            // 장바구니에 정보가 없다면, uid를 초기화한다.
                            state.uid = null
                        }

                        if (!res.data.auth && state.member) {
                            dispatch('getCartConfig')
                        }

                        // Config check
                        if (res.data.auth !== null && state.member === null) {

                            // 장바구니에 고객 정보가 있고, 고객 정보가 비어있다면 갱신
                            dispatch('getCartConfig')
                        } else if (res.data.coupon !== null && state.coupon === null) {

                            // OR 장바구니에 쿠폰 정보가 있고, 쿠폰 정보가 비어있다면 갱신
                            dispatch('getCartConfig')
                        }

                    })
            } else if (state.mode === 'edit') {

                // 수정 모드
                return await axios.get('/api/store/order/' + state.order.od_id)
                    .then(res => {
                        commit('setCart', res.data.items)

                    })
            }
        },
        /**
         * 세션의 장바구니에 상품(옵션)을 추가한다.
         * @param commit
         * @param dispatch
         * @param state
         * @param {Object} payload
         * @param {Number} payload.op_id
         * @param {Number} payload.qty
         * @param {Array} payload.options
         * @returns {Promise<void>}
         */
        async postMyCart({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.post('/api/store/my-cart', payload)
                    .then((res) => {
                        // 등록시 바로 장바구니를 리턴하도록 수정
                        if (res.status === 206 && res.data.message) {
                            messenger.warning(res.data.message);
                            dispatch('getMyCart');
                            return;
                        }
                        commit('setCart', res.data.data)
                    }).catch((err) => {
                        const res = err.response;
                        if (res.message) {
                            messenger.error(res.message);
                        }
                    })

            } else if (state.mode === 'edit') {

                return await axios.post(`/api/store/me/orders/${state.order.od_id}/items`, payload).finally(res => {
                    dispatch('getMyCart')
                });

            }
        },
        /**
         * 세션 장바구니에 담긴 상품(ct_id) 의 수량(ct_qty)을 변경한다.
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {{ct_id: Number, quantity: Number}}
         * @return
         */
        async putMyCart({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.put('/api/store/my-cart/' + payload.ct_id, {
                    ct_qty: payload.quantity,
                }).finally(() => {
                        dispatch('getMyCart')
                    })
            } else if (state.mode === 'edit') {

                //수정 모드
                let item = state.items.find(item => item.ct_id = payload.ct_id)
                return await axios.put(`/api/store/me/orders/${state.order.od_id}/items/${item.ct_id}`, {
                    ct_qty: payload.quantity,
                    ct_amount: item.ct_amount,
                    ct_status: item.ct_status,
                }).finally(res => {
                    dispatch('getMyCart')
                })
            }
        },
        /**
         * 아이템들의 상태를 변경한다 (수정 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {{items: item[], status: String}} items Array
         * @returns {Promise<void>}
         */
        changeItemsStatus({commit, dispatch, state}, payload) {
            if (state.mode === 'edit') {

                // 판매 모드일 때만 동작
                payload.items.forEach(async (item, index, items) => {
                    await axios.put(`/api/store/me/orders/${state.order.od_id}/items/${item.ct_id}`, {
                        ct_status: payload.status,
                        ct_amount: item.ct_amount,
                        ct_qty: item.ct_qty
                    }).catch(err => {
                        if (err.response.data.ct_status) {
                            messenger.$message({type: "error", message: err.response.data.ct_status[0]})
                        } else {
                            messenger.$message({type: "error", message: '상태 변경 실패'})
                        }
                    }).finally(() => {
                        if (index === items.length - 1) {
                            dispatch('getMyCart');
                        }
                    });
                })
            }
        },
        /**
         * 세션 장바구니에 담긴 상품을 삭제한다.
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {Number} 삭제할 ct_id
         * @returns {Promise<void>}
         */
        async deleteMyCart({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {
                state.items = state.items.filter((item) => {
                    return item.ct_id !== payload
                });
                // 판매 모드
                return await axios.delete('/api/store/my-cart/' + payload)
                    .then(res => {

                        dispatch('getMyCart')
                    })
            } else if (state.mode === 'edit') {

                return await axios.delete(`/api/store/me/orders/${state.order.od_id}/items/${payload}`).then(res => {
                    dispatch('getMyCart')
                })
                // 수정 모드
                /*return await dispatch('orderUpdate', {
                    class: 'OrderUpdateItemDelete',
                    params: {
                        ct_id: payload
                    }
                })
                    .then(res => {

                        dispatch('getMyCart')
                    })*/
            }
        },
        /**
         * 장바구니에 회원 정보 세팅
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {Object} Member Object
         * @returns {Promise<void>}
         */
        async setMember({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.post('/api/store/customer-login', {
                    mb_id: payload.mb_id
                })
                    .then(res => {
                        commit('setMember', res.data.customer)
                        messenger.$message({type: "success", message: `${res.data.customer.mb_name}님으로 설정되었습니다.`})
                    })
                    .finally(() => {
                        dispatch('getMyCart')
                    })
            } else if (state.mode === 'edit') {

                // 수정 모드
                return await dispatch('orderUpdate', {
                    class: 'OrderUpdateCustomer',
                    params: {
                        mb_id: payload.mb_id
                    }
                })
                    .then((res) => {
                        commit('setMember', payload);

                        let dialogs = res.data.dialogs;
                        if (dialogs) {
                            let dialog = dialogs[0];
                            MessageBox.confirm(dialog.message).then(async (res) => {
                                let result = await axios({
                                    method: dialog.method,
                                    url: dialog.uri,
                                    data: dialog.data
                                });

                                await dispatch('getOrder', state.order.od_id);
                            })
                        }
                    })
            }
        },
        /**
         * 장바구니에 회원 정보 초기화
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {Object} Member Object
         * @returns {Promise<void>}
         */
        async unsetMember({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.post('/api/store/customer-logout', {
                    mb_id: state.member.mb_id
                })
                    .then(res => {
                        commit('setMember', res.data.customer)
                        messenger.$message({type: "success", message: '초기화 되었습니다.'})
                    })
                    .finally(() => {
                        dispatch('getMyCart')
                    })
            } else if (state.mode === 'edit') {
                // 주문 수정시에는 초기화 안됨
                messenger.$alert('주문수정시에는 초기화할 수 없습니다.');
            }
        },
        /**
         * 장바구니의 고객 / 쿠폰 정보를 가져온다 (판매모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<AxiosResponse<any>>}
         */
        async getCartConfig({commit, dispatch, state}) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.get('/api/store/my-cart-config')
                    .then(res => {
                        commit('setMember', res.data.auth)
                        commit('setCoupon', res.data.coupon)
                    })
            }
        },
        /**
         * 고객 등급 기준 가격 변경
         * @param commit
         * @param dispatch
         * @param state
         * @param payload
         * @returns {Promise<unknown>}
         */
        async setMemberPrice({commit, dispatch, state}, payload) {
            let member
            if (state.member) {
                member = state.member
            } else if (payload) {
                member = payload
            } else {
                return
            }

            if (state.mode === 'new') {

                // 판매 모드
                return await axios.post('/api/store/my-cart-config', {
                    action: 'member',
                    params: {
                        mb_id: member.mb_id
                    }
                })
                    .then(res => {
                        commit('setMember', payload)
                        messenger.$message({type: "success", message: res.data.message})
                    })
                    .finally(() => {
                        dispatch('getMyCart')
                    })
            } else if (state.mode === 'edit') {

                // 수정 모드
                return await dispatch('orderUpdate', {
                    class: 'OrderUpdateItemPriceByMember',
                    params: {
                        mb_level: member.mb_level
                    }
                })
                    .then(res => {

                        dispatch('getMyCart')
                    })
            }
        },
        /**
         * 사용가능한 쿠폰 조회 (판매 모드 전용)
         * @returns {Promise<AxiosResponse<any>>}
         */
        async couponAvailable({state}) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.get('/api/store/coupon/available-coupons')
                    .then(res => {
                        if (res.data.coupons.length === 0) {
                            messenger.$message({type: 'error', message: '사용가능한 쿠폰이 없습니다.'})
                        }

                        return Promise.resolve(res.data.coupons || [])
                    })
            }
        },
        /**
         * 쿠폰 활성화
         * @param dispatch
         * @param state
         * @param commit
         * @param payload
         * @returns {Promise<AxiosResponse<any>>}
         */
        async couponActivate({dispatch, state, commit}, payload) {
            if (state.mode === 'new') {

                // 판매 모드
                return await axios.post('/api/store/coupon/activate', {
                    code: payload.issue_code
                })
                    .then(res => {
                        messenger.$message({type: "success", message: res.data.message})
                        commit('setCoupon', payload)
                        dispatch('getMyCart')

                        return Promise.resolve(res)
                    })
            } else if (state.mode === 'edit') {

                // 수정 모드
                return await dispatch('orderUpdate', {
                    class: 'OrderUpdateCouponApply',
                    params: {
                        coupon_id: payload.id
                    }
                })
                    .then(res => {
                        messenger.$message({type: "success", message: res.data.message})
                        commit('setCoupon', payload)
                        dispatch('getOrder', state.order.od_id)

                        return Promise.resolve(res)
                    })
            }
        },
        /**
         * 쿠폰 적용 취소
         * @param dispatch
         * @param state
         * @param commit
         * @param payload
         * @returns {Promise<AxiosResponse<any>>}
         */
        async couponInactive({dispatch, state, commit}, payload) {


            if (state.mode === 'new') {
                return await axios.post('/api/store/coupon/inactivate', {
                    code: state.coupon.issue_code
                })
                    .then(() => {
                        commit('clearCoupon')
                        dispatch('getMyCart')
                    })
            } else {
                return await dispatch('orderUpdate', {
                    class: 'OrderUpdateCouponUnset',
                    params: {
                        issue_id: state.order.coupon_issue.id
                    }
                })
                    .then(res => {
                        messenger.$message({type: "success", message: res.data.message})
                        commit('clearCoupon')
                        dispatch('getMyCart')
                    })
            }

        },
        /**
         * 도매가 / 소매가 적용
         * @param dispatch
         * @param state
         * @param payload {String} ['shopper','wholesale']
         */
        async priceType({dispatch, state, rootState}, payload) {
            let on_uid;
            const items = state.items.map(item => item && item.on_uid ? item.on_uid : null);
            if (items.length) {
                on_uid = items[0].on_uid;
            }
            let mb_level
            switch (payload) {
                case 'wholesale':
                    mb_level = 5
                    break;
                case 'shopper':
                    mb_level = 2
                    break;
                default:
                    return;
            }
            // TODO :: 주문 수정시에는 다른 API 를 써야 합니다.
            /*if (state.mode === 'new') {*/
            return await axios.post('/api/store/my-cart-config', {
                action: 'level',
                params: {
                    mb_level: mb_level,
                    on_uid: on_uid
                }
            }).then(res => {
                if (res.data.updated === true) {
                    messenger.$message({type: "success", message: res.data.message})
                    dispatch('getMyCart')
                    state.wholesale = mb_level >= 5
                }
            })
            /* } else if (state.mode === 'edit') {

                return await axios.post("/api/v1/resource/order-updater",
                    {
                        class_name: 'App\\Actions\\Order\\OrderUpdateItemPriceByMember',
                        parameters: {
                            od_id: state.order.od_id,
                            mb_level: mb_level,
                            store_id: rootState.user.user.store_id
                        }
                    }).then(res => {
                    messenger.success( res.data.message );
                    dispatch('getOrder', state.order.od_id)
                }, err => {
                    const res = err.response;
                    this.errors = res.data;
                });
            }*/
        },
        /**
         * 주문 등록 (판매 모드 전용)
         * @param commit
         * @param state
         * @param payload
         * @returns {Promise<AxiosResponse<any>>}
         */
        async postOrder({commit, state}, payload) {
            if (state.mode === 'new') {
                return await axios.post('/api/store/order', payload)
                    .then(res => {
                        messenger.$message({type: "success", message: "저장되었습니다."})
                        commit('clearCart')
                        commit('setPrevOrder', res.data.order.od_id)
                        return Promise.resolve(res)
                    })
            }
        },
        /**
         * 장바구니 초기화 (판매 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<AxiosResponse<any>>}
         */
        async clearCart({commit, dispatch, state}) {
            if (state.mode === 'new') {
                return await axios.post('/api/store/my-cart-config', {
                    action: 'reset',
                    params: {
                        on_uid: state.uid
                    }
                })
                    .then(res => {
                        commit('clearCart')
                        dispatch('getMyCart')

                        messenger.$message({type: "success", message: res.data.message})
                    })
            }
        },
        /**
         * 본사 발송 요청 (주문 수정 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @param payload
         * @returns {Promise<void>}
         */
        async sendAgencyOrder({commit, dispatch, state}, payload) {

            if (state.mode === 'edit') {
                payload.od_id = state.order.od_id
                payload.dl_id = state.order.dl_id

                await axios.post('/api/store/agency-order', payload)
                    .then(res => {
                        messenger.$message({type: "success", message: res.data.message})
                        return Promise.resolve(res)
                    })
                    .catch(error => {
                        if (error.response.data?.od_id) {
                            messenger.$message({type: "error", message: error.response.data.od_id[0]})
                        }
                        return Promise.reject(error)
                    })
            }
        },
        /**
         * 배송비 계산 (판매 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @param payload {{dl_id: Number, od_b_country: String}} dl_id: 배송회사 (1:국내택배, 2: 해외발송), od_b_country: 국가코드(?)
         * @returns {Promise<void>}
         */
        async getShipmentCost({commit, dispatch, state}, payload) {
            if (state.mode === 'new') {
                return await axios.get('/api/store/shipment-cost', {
                    params: payload
                })
                    .then(res => {
                        // 배송비 total 값을 업데이트함
                        commit('setOrderField', {field: 'od_send_cost', value: res.data.total})
                        messenger.$message({type: "success", message: `배송비가 입력되었습니다. (배송비: ${res.data.total}원)`})
                        return Promise.resolve(res)
                    })
            }
        },
        /**
         * 주문 금액 수정 (주문수정 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<any>}
         */
        async updateOrderPayment({commit, dispatch, state}) {
            if (state.mode === 'edit') {

                return await dispatch('orderUpdate', {
                    class: 'OrderUpdatePayment',
                    params: {
                        od_receipt_point: state.order.od_receipt_point,
                        od_refund_cash: state.order.od_refund_cash,
                        od_cancel_card: state.order.od_cancel_card,
                        od_refund_amount: state.order.od_refund_amount,
                        od_dc_amount: state.order.od_dc_amount,
                        od_dc_memo: state.order.od_dc_memo,
                        od_receipt_bank: state.order.od_receipt_bank,
                        od_receipt_card: state.order.od_receipt_card,
                        od_bank_time: (state.order.od_receipt_bank > 0) ? new moment().format('YYYY-MM-DD') : null,
                        od_card_time: (state.order.od_receipt_card > 0) ? new moment().format('YYYY-MM-DD') : null,
                        od_send_cost: state.order.od_send_cost,
                        od_exdelivery: state.order.od_exdelivery || 0,
                    }
                })
                    .then(res => {

                        messenger.$message({type: "success", message: res.data.message})
                        return Promise.resolve(res)
                    })
            }
        },
        /**
         * 주문 삭제 (주문 수정 모드 전용)
         * @param commit
         * @param dispatch
         * @param state
         * @returns {Promise<any>}
         */
        async deleteOrder({commit, dispatch, state}, payload) {
            return await axios.delete('/api/store/order/' + payload)
                .then(res => {
                    commit('clearCart')
                    dispatch('getMyCart')
                    this.$router.push({name: 'Sales'});

                    new Promise.resolve(res)
                })
        },
        getUpdateAdminMemo({commit, dispatch, state}, payload) {
            if (state.mode === 'edit') {
                return dispatch('orderUpdate', {
                    class: 'OrderUpdateAdminMemo',
                    params: {
                        od_comment: payload
                    }
                })
                    .then(res => {
                        messenger.$message({type: "success", message: res.data.message})
                    })

            } else {
                return new Error('잘못된 요청입니다. 주문 정보를 확인해주세요.')
            }
        },
        /**
         * 주문 보류
         * @param commit
         * @param state
         * @param dispatch
         * @param payload {String} 주문고객명
         */
        async reserveMyCart({commit, state, dispatch}, payload) {

            if (state.mode === 'new') {

                // 아이템을 하나라도 등록해 uid가 있을 경우에만 보류 가능
                if (state.uid === null) {
                    messenger.$message({type: "error", message: "빈 장바구니는 보류할 수 없습니다."})
                    return Promise.reject('빈 장바구니는 보류할 수 없습니다.');
                }

                let pendingCart = {
                    on_uid: state.uid,
                    name: payload
                }

                return await axios.post('/api/store/reserve-my-cart', pendingCart)
                    .then(res => {
                        commit('addPendingCart', pendingCart)
                        dispatch('getMyCart')
                        return Promise.resolve(res)
                    })
                    .catch(error => {
                        if (error.response.data?.on_uid) {
                            messenger.$message({type: "error", message: error.response.data.on_uid[0]})
                        }
                        return Promise.reject(error)
                    })
            }
        },
        /**
         * 주문 보류 복구
         * @param commit
         * @param state
         * @param dispatch
         * @param payload {String} 복구할 장바구나 uid
         */
        async restoreMyCart({commit, state, dispatch}, payload) {
            if (state.mode === 'new') {

                return await axios.post('/api/store/restore-my-cart', {
                    on_uid: payload
                })
                    .then(res => {

                        commit('removePendingCart')
                        dispatch('getMyCart')
                        return Promise.resolve(res)
                    })
                    .catch(error => {
                        if (error.response.data?.on_uid) {
                            messenger.$message({type: "error", message: error.response.data.on_uid[0]})
                        }
                        return Promise.reject(error)
                    })

            }
        },
        /**
         * 주문 수정 모드 컨트롤 액션
         * @param state
         * @param payload {{class: String, params: Object}}
         * @returns {Promise<unknown>}
         */
        async orderUpdate({state}, payload) {


            payload.params.od_id = state.order.od_id

            // 파라미터 필터링. 빈 파라미터는 보낼 수 없다.
            let filteredParameters = {}
            for (const key of Object.keys(payload.params)) {

                if (payload.params[key] !== null) {
                    filteredParameters[key] = payload.params[key]
                }
            }
            if (Object.keys(payload.params).length === 0) throw new Error('반드시 파리미터가 지정되어야 합니다.')


            return await axios.post('/api/store/order-updater', {
                class_name: `App\\Actions\\Order\\${payload.class}`,
                parameters: filteredParameters
            })
        },
    },
}
