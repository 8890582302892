import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import sales from "./modules/sales"
import stock from "./modules/stock"
import config from "./modules/config"
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: user,
    sales: sales,
    stock: stock,
    config: config,
  },
  plugins: [
    createPersistedState({
      paths: ['user', 'config']
    })
  ],
})
