import ErrorHandler from './evans/plugins/ErrorHandler'
import app from './main'
import store from './store/index'

const axios = require('axios');
axios.defaults.withCredentials = true;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://candleworks.co.kr' || 'localhost'
} else {
    //axios.defaults.baseURL = 'localhost'
}

if (store.getters['user/user']) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters['user/user'].token}`;
}

axios.interceptors.request.use(config => {
    app.$Progress.start()
    return config
})

axios.interceptors.response.use(
    (res) => {
        app.$Progress.finish()
        if (!res) {
            //window.vm.$message.warning( __('현재 서버 접속이 원활하지 않습니다. 새로고침 해 주세요.', 'Server is busy now. Please refresh this web page.'));

        }
        return res;
    },
    error => {
        app.$Progress.fail()
        return ErrorHandler(error)
    },
);

export default axios
