<template>
</template>
<script>
export default {
  name: "Product",
  props: {
    price: {},
    isGift: {
      default: false
    },
    handler:{
      type: Function,
      default: null
    }
  },
  data() {
    return {
      product: {},
      params: {
        op_id: '',
        it_id: '',
        options: [],
        qty: 1,
        is_gift: false
      },
      errors: {}
    }
  },
  methods: {
    async getProduct() {
      const res = await this.axios.get("/api/v1/product/" + this.price.it_id);
      this.product = res.data;
    },
    postCartItemCall(){
      let response;
      return this.$store.dispatch('sales/postMyCart', this.params)
          .then(res => {
            response = res
          })
          .catch(error => {
            response = error.response
            this.errors = response
          })
          .finally(() => {
            this.$emit('complete', response)
          });
    },
    async postProduct() {
      if(this.handler){
        return await this.handler(this);
      }
      await this.postCartItemCall()
    },
    setupParams() {
      this.params.op_id = this.price.op_id;
      this.params.it_id = this.price.it_id;
      this.params.is_gift = this.isGift;

    },
    handle() {
      this.setupParams();
      this.postProduct();
    }
  },
  mounted() {
    this.handle();
  }
}
</script>

<style scoped>

</style>
