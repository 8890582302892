<template>
  <ev-card body-classes="p-2">
    <ev-form class="w-full flex"
             v-model="user"
             :errors="errors"
    >
      <div class="inline-block mr-4 text-bold self-center">
        출근 / 퇴근
      </div>
      <ev-form-field class="inline-block w-48"
                     placeholder="아이디를 입력하세요"
                     field="mb_id"
      />
      <ev-form-field class="inline-block w-48 mx-4"
                     type="password"
                     placeholder="비밀번호를 입력하세요"
                     field="mb_password"
      />

      <ev-form-field class="ml-auto mr-0">
        <ev-button class="inline-block flex w-32 items-center rounded px-4 mx-4 font-bold"
                   @click="setAttendance('on')"
        >
          <span class="">출근</span>
        </ev-button>
      </ev-form-field>
      <ev-form-field class="ml-auto mr-0">
        <ev-button class="inline-block w-32 flex items-center rounded px-4 mx-4 font-bold"
                   @click="setAttendance('off')"
        >
          <span class="">퇴근</span>
        </ev-button>
      </ev-form-field>
      <ev-form-field class=" mr-0">
        <ev-button class="inline-block flex w-32 items-center rounded px-4 mx-4 font-bold"
                   @click="getAttendance"
        >
          <span class="">확인</span>
        </ev-button>
      </ev-form-field>
      <ev-form-field class="mr-0">
        <ev-button class="rounded"
                   type="plain"
                   @click="showConfig = !showConfig"
        >설정
        </ev-button>
      </ev-form-field>
    </ev-form>
    <div v-show="showConfig">
      <hr class="my-4"/>
      <div class="flex">
        <div class="ml-auto mr-0">
          출근시간
          <ev-input v-model.number="workStart.hour"/>
          <ev-input v-model.number="workStart.minute"/>
          <ev-button type="primary"
                     @click="$store.commit('config/setWorkStartTime', {
                                 hour: Number(workStart.hour),
                                 minute: Number(workStart.minute)
                             })"
          >저장
          </ev-button>
        </div>
      </div>
    </div>
  </ev-card>
</template>

<script>
import axios from "../../axiosConfig";
import {messenger} from "@/evans/plugins/EvToast";
import moment from 'moment'

export default {
  name: "attendance",

  props: {
    commitLog: {type: Array}
  },

  data() {
    return {
      user: {
        mb_id: null,
        mb_password: null,
      },
      errors: {},
      showConfig: false,
      workStart: {
        hour: this.$store.getters['config/workStartHour'],
        minute: this.$store.getters['config/workStartMinute']
      }
    }
  },

  methods: {
    getAttendance() {
      // validate
      if (!this.user.mb_id) {
        messenger.warning('아이디가 입력되지 않았습니다.');
        return;
      }

      axios.get('/api/branch/commit-logs', {
        params: {
          mb_id: this.user.mb_id,
          sort: 'ev_uid',
          order: 'desc',
        }
      })
          .then(res => {
            this.$emit('update:commit-log', res.data.data);
            this.$emit('attendanceRefresh');
          })
    },
    setAttendance(attendanceType) {
      // validate
      if (!this.user.mb_id) {
        messenger.warning('아이디가 입력되지 않았습니다.');
        return;
      }
      if (!this.user.mb_password) {
        messenger.warning('비밀번호가 입력되지 않았습니다.');
        return;
      }
      if (!attendanceType) {
        messenger.warning('출/퇴근 구분이 선택되지 않았습니다.');
        return;
      }

      this.$confirm(`현재 시간은 ${moment().format('hh:mm:ss')} 입니다.
                    ${attendanceType === 'on' ? '출근' : '퇴근'}처리 하시겠습니까?`)
          .then(() => {

            // post
            axios.post('/api/branch/commit-logs-via-login', {
              mb_id: this.user.mb_id,
              mb_password: this.user.mb_password,
              type: attendanceType
            })
                .then(res => {
                  if (res.status === 200) {
                    if (attendanceType === 'on') {
                      messenger.success('출근이 정상적으로 처리되었습니다.');
                    } else {
                      messenger.success('퇴근이 정상적으로 되었습니다.');
                    }
                    this.getAttendance();
                    this.$emit('attendanceRefresh')
                  } else {
                    throw new Error('response code is not 200 , (code:' + res.status + ')')
                  }
                })
                .catch(err => {
                  let errorMessage = err?.response?.data?.type ? err.response.data.type[0] : '출/퇴근 처리 중 오류가 발생했습니다.'
                  this.errors = err.response.data
                  messenger.warning(errorMessage);
                  console.error(err);
                });
          })
    }
  },
}
</script>
