<template>
  <div>
    <div class="bg-white rounded shadow">
      <div class="p-2 text-lg">
        {{ order.od_id }} | {{ order.od_time }} | {{ order.od_comment }}
      </div>
    </div>
    <div v-show="order.od_status === '완료'" class=" my-2 p-2 bg-red-600 rounded text-white shadow ">
      이미 입고가 된 주문입니다. 주의 하세요.
    </div>
    <div class="rounded shadow-lg my-4 bg-white p-0">
      <h3 class="p-2 font-bold text-xl ">일괄입고 내역 {{ (orderId) ? '#' + orderId : '' }} </h3>
      <grid-template
          v-model="order.items"
          :errors="errors"
          primary-key="ct_id"
          :field-width="{
      ct_status: 80,
      ct_qty: 100,
      op_name: 120,
      in_qty: 100,
      memo:120
                   }"
          :field-labels="{
      ct_status: '상태',
      op_itname: '상품명',
      op_name: '단위',
      ct_qty:'주문수량',
      in_qty:'입고수량',
      memo:'위치'
    }"
          cell-type="value"
          pagination
      >
        <template v-slot:cell:in_qty="{value,handler}">
          <ev-input class="w-full" :value="value" @input="handler"/>
        </template>
        <template v-slot:cell:memo="{value,handler}">
          <ev-input class="w-full" :value="value" @input="handler"/>
        </template>
      </grid-template>
    </div>
    <div>
      <el-button type="primary" @click="receiving">입고하기</el-button>
    </div>
  </div>
</template>

<script>
import GridTemplate from "@/evans/templates/GridTemplate";
import {postInventoryItems} from "@/apis/inventory";
import axios from "@/axiosConfig";
import EvPriceSelector from "@/evans/controls/EvPriceSelector";
import PurchaseList from "@/views/Purchase/PurchaseList";
import {confirmReceiveOrder} from "@/apis/purchase";

export default {
  name: "PurchaseReceive",
  components: {PurchaseList, EvPriceSelector, GridTemplate},
  props: {
    orderId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      uri: '/api/store/me/inventories',
      order: {
        items: []
      },
      openDialog: false,
      newData: {},
      errors: {},
      selector: {}
    }
  },
  computed: {
    selectedItems(val, old) {
      return this.order.items.filter(item => item.__is_selected)
    },
    isClosedOrder(){
      return this.order.od_status === '완료';
    }
  },
  methods: {
    setItems(items) {
      items.forEach((item) => {
        this.items.push(item);
      })
    },
    async getOrder() {
      if (!this.orderId)
        return;
      // 주문상품 데이터와 함께 현재고를 함께 가져옵니다.
      const response = await axios.get('/api/store/me/purchases/' + this.orderId);
      this.order = response.data;
      this.order.items.map(item => {
        item.in_qty = item.ct_qty;
      })

    },
    async closePurchase() {
      try {
        await this.$confirm('이 발주 주문이 입고완료된 상태로 표시 됩니다. 변경할까요?');
      } catch (e) {
        return;
      }
      await confirmReceiveOrder(this.orderId);
      this.$message.success(this.orderId + '입고 완료 등록');

      this.$router.push('/inventory')
    },
    async receiving() {
      if (!this.selectedItems.length) {
        this.$message.warning('입고할 상품을 선택해 주세요.');
        return;
      }

      if(this.isClosedOrder){
        try {
          await this.$confirm('이미 입고처리가 된 주문입니다. 다시 진행 하시겠습니까?');
        } catch (e) {
          return;
        }
      }

      try {
        await this.$confirm('선택한 모든 상품을 재고 등록하시겠습니까?');
      } catch (e) {
        return;
      }

      let isSuccess = false;
      for (const item of this.selectedItems) {
        try {

          await postInventoryItems({
            wh_id: this.$store.getters["user/user"].store_id,
            op_id: item.op_id,
            in_qty: item.in_qty,
            type: 'A',
            memo: "[입고]#" + this.order.od_id
          })
          this.$message.success(item.op_itname + ' 입고 등록');
          isSuccess = true;

        } catch (e) {
          const res = e.response;
          if (res.status === 422) {
            this.$set(this.errors, item.ct_id, res.data);
          }
        }
      }
      if (isSuccess) {
        await this.closePurchase();
      }
    },

  },
  mounted() {
    this.getOrder();
  }
}
</script>

<style scoped>

</style>
