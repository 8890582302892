<template>
  <div>
    <ev-form class="my-4 p-2 bg-white rounded" v-model="query" :errors="errors">
      <ev-form-field field="it_name" label="상품명"></ev-form-field>
      <ev-form-field field="it_id" label="상품번호"></ev-form-field>
      <ev-form-field field="op_id" label="단위">
        <ev-price-selector value-key="op_id" v-model="query.op_id"></ev-price-selector>
      </ev-form-field>
      <ev-form-field field="reportBy" label="정렬기준">
        <ev-select v-model="query.reportBy" :options="[
            {value:'it_id', label:'상품기준'},
            {value:'op_id', label:'단위기준'},
        ]"></ev-select>
      </ev-form-field>
      <el-button @click="getItems">검색</el-button>
    </ev-form>
    <div class="my-4">
      <el-button type="primary" @click="()=>{ openDialog = !openDialog }">새로 등록하기</el-button>
    </div>
    <el-dialog :visible.sync="openDialog">
      <inventory-form @created="createdItem"></inventory-form>
    </el-dialog>
    <grid-template v-model="items" :parent-data="this" pagination footable
                   :field-width="{
                      it_id : 100,
                      unit : 120,
                      unit_weight : 100,
                      stock : 100
                   }"
                   :field-labels="{
        it_id : '상품번호',
        it_name : '상품명',
        unit : '단위',
        unit_weight : '재고량',
        stock : '무게합계',
        memo: '메모',
        edit_qty : '조정수량'
      }" cell-type="value"
    >
      <template v-slot:cell:edit_qty="{row,value,handler}">
        <div class="w-24">
          <el-popover
              placement="bottom"
              trigger="manual"
              :value=" row.__is_selected && parseInt(value) > 0"
              >
            <div>
              <el-input type="textarea"
                        :value="row.edited_memo"
                        @input="(val)=>{updateEditedMemo(val,row)}"
                        placeholder="재고위치나 사유를 입력하세요." />
              <el-button type="primary" @click="()=>{postEditedInventory(row)}">조정하기</el-button>
              <el-button type="warning" @click="()=>{cancelEditedInventory(row)}">취소</el-button>
            </div>

            <el-input slot="reference" :value="value" @input="(v)=>{updateEditQty(v,handler)}" ></el-input>
          </el-popover>
        </div>
      </template>
      <template v-slot:cell:it_id="{value}">
        <div>
          <el-button @click="()=>{getPrices(value)}">{{ value }}</el-button>
        </div>
      </template>
      <template v-slot:cell:unit_weight="{value,row}">
        <div class="text-right">
          {{ row.stock / value  | formatNumber('usd') }}
        </div>
      </template>
      <template v-slot:cell:stock="{value, row}">
        <div class="text-right">
          {{ value | formatInt }}
        </div>
      </template>
      <template v-slot:foot:unit>
        <div class="text-right">
          요약:
        </div>
      </template>
      <template v-if="query.reportBy === 'op_id'" v-slot:foot:unit_weight>
        <div class="text-right">
          {{ getTotalCount | formatInt }}
        </div>
      </template>
      <template v-if="query.reportBy === 'op_id'" v-slot:foot:unit>

          {{ getDefaultUnit }}

      </template>
      <template v-if="query.reportBy === 'op_id'" v-slot:foot:stock>
        <div class="text-right">
          {{ getTotal | formatInt }}
        </div>
      </template>
    </grid-template>
  </div>
</template>

<script>
import AxiosHelper from "@/evans/plugins/AxiosHelper";
import EvPriceSelector from "@/evans/controls/EvPriceSelector";
import EvProductSelector from "@/evans/controls/EvProductSelector";
import GridTemplate from "@/evans/templates/GridTemplate";
import {postInventoryItems} from "@/apis/inventory";
import InventoryForm from "@/views/Inventory/InventoryForm";

export default {
  name: "InventoryReport",
  components: {InventoryForm, GridTemplate, EvProductSelector, EvPriceSelector},
  mixins: [
    AxiosHelper
  ],
  data() {
    return {
      uri: '/api/store/me/inventories',
      openDialog: false
    }
  },
  mounted() {
    this.query.reportBy = 'it_id';
    this.getItems();
  },
  methods: {
    getPrices(it_id) {
      this.query.it_id = it_id;
      this.query.reportBy = 'op_id';
      this.query.page = 1;
      this.getItems()
    },
    updateEditQty(val, handler) {
      handler(val);
    },
    async postEditedInventory(item) {
      console.log(item);
      await postInventoryItems({
        wh_id : this.$store.getters["user/user"].store_id,
        op_id : item.op_id,
        in_qty : item.edit_qty,
        type: 'A',
        memo: item.edited_memo ?? '재고조사'
      });

      await this.getItems();
    },
    cancelEditedInventory(row) {
      this.$set(row, '__is_selected', false);
      row.edit_qty = 0;
    },
    updateEditedMemo(value,row) {
      if(!row.edited_memo) {
        this.$set(row, 'edited_memo', value);
      }else{
        row.edited_memo = value;
      }
    },
    createdItem() {
      this.openDialog = false;
      this.getItems();
    }
  },
  computed: {
    getTotal() {
      return this.items.map(item => item.stock).reduce((a, b) => {
        return a + b
      })
    },
    getTotalCount() {
      let pop = this.items.map(item => item.unit_weight).sort((a, b) => {
        return (a < b) ? 1 : -1
      }).pop();
      console.log(pop);
      return this.getTotal / pop
    },
    getDefaultUnit() {
      let pop = this.items.map(item => item).sort((a, b) => {
        return (a.unit_weight < b.unit_weight) ? 1 : -1
      }).pop();
      return pop.unit || ''
    }
  }
}
</script>

<style scoped>

</style>
