import axios from "../../axiosConfig";
import router from "@/router/index";

import {messenger} from "../../evans/plugins/EvToast";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user: state => {
      if(state.user){
        return state.user
      } else {
        return false
      }
    }
  },
  mutations: {
    setUser(state, payload){
      state.user = payload
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
    },
    clearUser(state){
      state.user = null
      axios.defaults.headers.common['Authorization'] = undefined
      window.localStorage.removeItem('pos_user')
    }
  },
  actions: {
    /**
     * Authentication by Id & Password
     * @param commit
     * @param payload { {id: String, password: String, auto: Boolean } } id & password
     */
    async login({commit}, payload) {

      return await axios.post('/api/store/auth', {
        'mb_id': payload.id,
        'mb_password': payload.password,
      })
        .then(res => {
          //관리자 여부 확인
          if (!res.data.admin) {  // if(!res.data.user.is_admin){
            throw new Error("관리자만 접근할 수 있습니다.")
          }

          // 유저 정보 & 토큰 정보 세팅
          let userInfo = {
            store_id: res.data.store_id,
            mb_no: res.data.user.mb_no,
            mb_id: res.data.user.mb_id,
            mb_emp_name: res.data.user.mb_emp_name,
            token: res.data.token,
          }
          commit('setUser', userInfo)

          // 자동 로그인 > 토큰을 로컬 스토리지에 담음
          if (payload.auto) {
            window.localStorage.setItem('pos_user', JSON.stringify(userInfo))
          }

          
          // ?redirect="..." 로 이동
          router.push(router.app.$route.query.redirect || '/')

          return Promise.resolve("login success")
        })
    },
    /**
     * Authentication Token check
     * @param dispatch
     * @param commit
     * @returns {Promise<reason>}
     */
    async hello({dispatch, commit}){

      // 1. axios 헤더 확인, 없다면 2. 로컬 스토리지 확인
      if(axios.defaults.headers.common['Authorization'] === undefined){
        if(window.localStorage.getItem('pos_user')){
          commit('setUser',JSON.parse(window.localStorage.getItem('pos_user')) )
        } else {
          // 헤더와 로컬 스토리지에도 없다면 에러 호출
          return Promise.reject('there is no token')
        }
      }

      // hello 후 이전 로그인 기록 가져옴
      await axios.get('/api/store/hello')
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(() => {
          // 토큰 만료 등으로 실패하였다면 로그아웃 처리
          dispatch('logout')

          return Promise.reject("hello failed")
        })

    },
    logout({commit},payload = {alert: true}){
      commit('clearUser')
      router.push('/login')

      if(payload.alert){
        messenger.$message({type: "warning", message: "로그아웃 되었습니다."})
      }
    }
  },
}