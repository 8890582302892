var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('grid-template',{attrs:{"cell-type":"value","field-labels":{
                  op_id : '가격번호',
                  it_id : '상품번호',
                  op_itname : '상품명',
                  op_name : '단위',
                  total_count : '판매수량',
                 },"pagination":"","parent-data":this,"no-checker":""},scopedSlots:_vm._u([{key:"cell:op_id",fn:function({row}){return [_c('el-button',{on:{"click":()=>{_vm.createPurchaseItem(row)}}},[_vm._v(_vm._s(row.op_id))])]}}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }