<template>
  <ev-form v-model="newData" :errors="errors">

    <ev-form-field class="w-full" field="op_id" label="상품선택">
      <ev-price-selector :value="selector" @input="updateDataFormByPrice" ></ev-price-selector>
    </ev-form-field>
    <ev-form-field class="w-1/2" field="unit" label="단위" type="value"></ev-form-field>
    <ev-form-field class="w-1/2" field="unit_weight" label="무게재고" type="value"></ev-form-field>
    <ev-form-field class="w-1/3" field="in_qty" label="입고수량"></ev-form-field>
    <ev-form-field class="w-1/3" field="out_qty" label="출고수량"></ev-form-field>
    <ev-form-field class="w-1/3" field="type" label="유형">
      <template v-slot="{value,handler}">
        <ev-select :value="value" @input="handler" :options='[
            {label:"정상(A급)", value:"A"},
            {label:"하자(B급)", value:"B"},
            {label:"불량(C급)", value:"C"}
          ]'>
        </ev-select>
      </template>
    </ev-form-field>
    <ev-form-field class="w-full" field="memo" label="메모"></ev-form-field>
    <ev-form-field label="">
      <ev-button @click="createInventory"> 등록하기 </ev-button>
    </ev-form-field>
  </ev-form>
</template>

<script>
import EvPriceSelector from "@/evans/controls/EvPriceSelector";
import {postInventoryItems} from "@/apis/inventory";
export default {
  name: "InventoryForm",
  components: {EvPriceSelector},
  data() {
    return {
      newData: {
        type : 'A'
      },
      errors: {},
      selector: {}
    }
  },
  methods:{
    updateDataFormByPrice(price) {
      this.selector = price;
      if(!price){
        return;
      }
      this.newData.op_id = price.op_id || '';
      this.newData.unit = price.op_name || '';
      this.newData.unit_weight = price.op_weight || '';
    },
    async createInventory()
    {
      const res = await postInventoryItems({
        wh_id: this.$store.getters["user/user"].store_id,
        op_id: this.newData.op_id,
        in_qty: this.newData.in_qty,
        out_qty: this.newData.out_qty,
        type: this.newData.type,
        memo: this.newData.memo
      })

      this.$message.success('등록성공');
      this.$emit('created', res.data);
      this.newData = Object.assign({}, {type:'A'});
      this.selector = {};
    },
  }
}
</script>

<style scoped>

</style>
