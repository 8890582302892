<template>
    <ev-form>
        <table class='w-full'>
            <thead class="bg-gray-200">
                <tr class="bg-gray-900 text-white h-12">
                    <th>날짜</th>
                    <th>사원명</th>
                    <th>출근시간</th>
                    <th>퇴근시간</th>
                    <th>상태</th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-b h-12"
                    v-for="log in commitLog"
                >
                    <td>
                        {{ getDate(log.ev_sp) }}
                    </td>
                    <td>
                        {{ log.mb_id }}
                    </td>
                    <td>
                        {{ getTime(log.ev_sp) }}
                    </td>
                    <td>
                        {{ getTime(log.ev_ep) }}
                    </td>
                    <td v-if="isLate(log.ev_sp)"
                        class="font-bold text-pink-600"
                    >
                        지각
                    </td>
                    <td v-else>
                        정상
                    </td>
                </tr>
            </tbody>
        </table>
    </ev-form>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "NoticeAttendanceRecordList",

        props: {
            commitLog: {type: Array, default: []}
        },
        computed: {

        },
        methods: {
            getDate(val){
                return val.substring(0,10);
            },
            getTime(val){
                if(val === "0000-00-00 00:00:00"){
                    return "-"
                }
                return moment(val).locale('ko').format('LTS')
            },
            isLate(spTime){
                let sp = moment(spTime),
                    spHour = sp.hour(),
                    spMinute = sp.minute(),
                    overHour = this.$store.getters['config/workStartHour'], // 지각 판정 시간
                    overMinute = this.$store.getters['config/workStartMinute']; // 지각 판정 분
                return spHour > overHour || spHour === overHour && spMinute >= overMinute
            }
        }
    }
</script>
