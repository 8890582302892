<template>
  <ev-card body-classes="p-2">
    <ev-form v-model="query" :errors="errors">
      <ev-form-field field="date_range[start]" label="기간설정" type="datepicker"/>
      <ev-form-field field="date_range[end]" label="~" type="datepicker"/>
      <ev-form-field field="it_name" label="상품명"/>
      <ev-form-field field="op_id" label="옵션번호"/>
      <ev-form-field field="od_mode" label="매장발주" type="switch" active-value="4"  inactive-value="0"/>
      <ev-form-field class="ml-4">
        <ev-button type="primary"
                   @click="getItems()"
        >검색
        </ev-button>
      </ev-form-field>
    </ev-form>
  </ev-card>
</template>
<script>
export default {
  name: 'PurchaseSearchForm',
  props:{
    parent : {
      type: Object,
      required: true
    }
  },
  computed: {
    query:{
      get() {
        return this.parent.query
      },
      set(val){
        this.parent.query = val;
      }
    },
    errors() {
      return this.parent.errors;
    }
  },
  methods: {
    getItems() {
      this.parent.getItems();
    }
  }
}
</script>
