import axios from "@/axiosConfig";
import {getSerializedQuery} from "@/apis/ApiHelper";
import {AxiosResponse} from "axios";

export async function getInventoryItems(query) {
    return await axios.get("/api/store/me/inventories?" + getSerializedQuery(query));
}

/**
 *
 * @param {Object} params
 * @param {number} params.wh_id
 * @param {number} params.op_id
 * @param {number} params.qty
 * @param {string} [params.type=A|B|C]
 * @param {number} params.memo
 * @return {Promise<AxiosResponse<any>>}
 */
export async function postInventoryItems(params) {
    return await axios.post("/api/store/me/inventories", params);
}

export async function putInventoryItem(id, params) {
    return await axios.put('/api/store/me/inventories/me/items/' + id, params)
}

export async function deleteInventory(id) {
    return await axios.delete('/api/store/me/inventories/me/items/' + id);
}

export async function postInventoryOrder(refKey) {
    return await axios.post('/api/store/me/inventories', {on_uid: refKey})
}
