<template>
    <input
        ref="evInput"
        :class="getClasses"
        v-bind="$attrs"
        v-on:input="inputHandler"
        v-on:change="changeHandler"
    >
</template>
<script>
    export default {
        name: 'EvMoneyInput',
        data() {
            return {
                classes: [
                    "border-gray-200",
                    "appearance-none",
                    "border-1",
                    "h-10",
                    "py-2",
                    "px-4",
                    "leading-tight",
                    "focus:outline-none",
                    'transition-ease'
                ],
                isChanged: false,
                availablePasswordChar: /[0-9a-zA-Z!@#$%^&*]*/ig,
                invalid: false,
            }
        },
        computed: {
            getInputType() {
                return (this.$attrs['type']) ? this.$attrs['type'] : 'text';
            },
            hasErrorField() {
                return this.$parent.hasError;
            },
            getClasses() {
                let mode = 'default';
                if (this.hasErrorField) {
                    mode = 'error';
                }
                let typeClasses = [];
                switch (mode) {
                    case 'default':
                        typeClasses = [
                            "bg-gray-200",
                            "text-gray-700",
                            "focus:bg-gray-100",
                            "focus:border-gray-300",
                        ];
                        break;
                    case 'error' :
                        typeClasses = [
                            "bg-red-200",
                            "text-red-700",
                            "focus:bg-red-100",
                            "focus:border-red-300",
                        ];
                        break;
                }
                if (this.$attrs['type'] === 'password') {
                    typeClasses.push('ime-inactive');
                }

                return typeClasses.concat(this.classes);
            },
            isPasswordType() {
                return this.$attrs['type'] === 'password';
            }
        },
        methods: {
            inputHandler(e) {
                let value = e.target.value;

                if (this.isPasswordType && !this.availablePasswordChar.test(value)) {
                    if (!this.invalid) {
                        this.$message({
                            type: 'warning', content: '영문자와 특수문자만 사용할 수 있습니다.', floating: true
                        });
                        this.invalid = true;
                        setTimeout(() => {
                            this.invalid = false;
                        }, 3000);
                    }
                    value = '';
                }
                this.$emit('input', value);

            },
            changeHandler(e) {
                this.$emit('change', e.target.value);
            }
        }
    }
</script>
