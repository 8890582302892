<template>
  <span>{{ !this.isUSD ? '₩' : '$' }}</span>
</template>
<script>
export default {
  name: "CurrencyIcon",
  props: {
    fix: null
  },
  computed: {
    isUSD() {
      return this.fix === 'usd';
    },
  }
}
</script>
