<template>
    <div @click="toggle"
         class="relative transition-all ease-in-out duration-300 border border-main-200 cursor-pointer w-12 h-6" :class="getStatusClass" style="width: 46px; border-radius: 46px;">
        <span class="absolute left-0 transition-all ease-in-out duration-300 rounded-full border w-4 h-4 border-gray-300 shadow-inner bg-white shadow" :class="getStatusPosition"></span>
    </div>
</template>

<script>
    export default {
        name: "EvSwitch",
        props:{
            value : null,
            activeValue:{
                default:true
            },
            inactiveValue:{
                default:false
            }
        },
        computed: {
            isActive(){
                return this.value == this.activeValue;
            },
            getStatusClass() {
                if( this.isActive ){
                    return ['bg-blue-600']
                }else{
                    return ['bg-blue-200']
                }
            },
            getStatusPosition(){
                if( this.isActive ){
                    return ['switch-on']
                }else{
                    return ['switch-off']
                }
            }
        },
        methods: {
            toggle() {

                if(this.value == this.activeValue){
                    this.$emit('input', this.inactiveValue);
                }else{
                    this.$emit('input', this.activeValue);
                }
            }
        }
    }
</script>

<style scoped>
    .switch-on{
        top: 50%;
        transform: translateY(-50%) translateX(150%);

    }
    .switch-off{
        top: 50%;
        transform: translateY(-50%) translateX(20%);
    }
</style>
