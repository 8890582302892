import store from "@/store";

export default class ConfigStore {
    $storage = {};
    $CryptoJS;
    constructor() {
        this.$CryptoJS = require("crypto-js");
        this.load();
    }
    load()
    {
        this.$storage = store.getters['config/pos'];
    }
    save()
    {
        store.commit('config/setConfig', this.$storage);
    }
    getStorage()
    {
        return JSON.parse( JSON.stringify(this.$storage) );
    }
    get(field){
        return this.$storage[field] || '';
    }
    set(field, value){
        if(field === 'managerPassword'){
            this.$storage[field] = this.encrypt(value);
        }else{
            this.$storage[field] = value;
        }
        this.save();
    }
    check(value) {
        if(!this.$storage.managerPassword){
            return value === 8914 || value === '8914';
        }
        return this.decrypt(this.$storage.managerPassword) === value;
    }
    decrypt(ciphertext){
        const bytes = this.$CryptoJS.AES.decrypt(ciphertext, 'C@ndleworks');
        return bytes.toString(this.$CryptoJS.enc.Utf8);
    }
    encrypt(value){
        return this.$CryptoJS.AES.encrypt(value, 'C@ndleworks').toString();
    }
}