<template>
  <div>

    <!-- 근태 영역 -->
    <notice-attendance-record :commit-log.sync="commitLog"
                              @attendanceRefresh="()=>{ tabSelected = 'notice-attendance-record-list'; }"
    />

    <!-- 탭 -->
    <simple-tab class="my-2"
                :tab-list="tabList"
                :separator="false"
                v-model="tabSelected"
    />

    <!-- 탭에서 선택된 컴포넌트 표시 -->
    <component :is="tabSelected"
               :commit-log="commitLog"
    />

  </div>
</template>

<script>
  import PosHeader from "../components/posHeader";
  import NoticeAttendanceRecord from "../controls/notice/AttendanceRecord";
  import NoticeList from "../controls/notice/NoticeList";
  import SimpleTab from "../components/simpleTab";
  import NoticeAttendanceRecordList from "../controls/notice/AttendanceRecordList";
  import EaseFadeTransition from "../evans/transition/EaseFadeTransition";
  import axios from "../axiosConfig";
  //import AppUpdateCheck from "@/controls/notice/AppUpdateCheck";

  export default {
    name: "Notice",

    components: {
      //AppUpdateCheck,
      EaseFadeTransition,
      NoticeAttendanceRecordList,
      SimpleTab,
      PosHeader,
      NoticeAttendanceRecord,
      NoticeList
    },

    data(){
      return {
        tabList: [
          { name: '공지사항', value: 'notice-list' },
          { name: '출퇴근 조회', value: 'notice-attendance-record-list'}
        ],
        tabSelected: 'notice-list',
        commitLog: [],
      }
    },

    methods: {
      /**
       * API에서 출퇴근 이력을 불러온다.
       * @param tabShow
       */
      getAttendance(tabShow = false){
        axios.get('/api/v1/resource/commit-log',{
          params: {
            mb_id: 'apgujong',
            sort: 'ev_uid',
            order: 'desc',
          }
        })
          .then(res => {
            this.commitLog = res.data.data

            if(tabShow === true){

            }
          })
      }
    }
  }
</script>

<style scoped>

</style>
