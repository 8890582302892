<template>
  <el-card>
    <h2 class="text-2xl font-bold flex justify-between ">
      발주일시 {{ order.od_time }}
      <el-button type="primary" v-if="order.od_mode === '4'" @click="goToReceive">입고하기</el-button>
    </h2>

    <el-row type="flex" style="" v-if="printable">
      <el-col :span="12" :xs="24" class="customer-section pc-only" style="padding-right: 10px">
        <evans-box :title="__('공급받는자','Customer')">
          <table class="column-table">
            <tr>
              <td class="col-head">{{ __('주문번호', 'Invoice Number') }}</td>
              <td>{{ order.od_id }}</td>
              <td class="col-head">{{ __('주문자명', 'Name') }}</td>
              <td>{{ order.od_name }} ({{ getMemberGroup(order) }})</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('전화번호', 'Phone') }}</td>
              <td>{{ order.od_tel }}</td>
              <td class="col-head">{{ __('핸드폰', 'Mobile') }}</td>
              <td>{{ order.od_hp }}</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('주소', 'Address') }}</td>
              <td colspan="3">({{ order.od_zipcode }}) {{ order.od_addr1 }}</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('상세주소', 'Street Address') }}</td>
              <td colspan="3">{{ order.od_addr2 }}</td>
            </tr>
          </table>
        </evans-box>
      </el-col>
      <el-col :span="12" :xs="24" class="shipment-section pc-only" style="padding-left: 10px">
        <evans-box :title="__('공 급 자', 'Company')">
          <table class="column-table" v-if="isKorean">
            <tr>
              <td class="col-head">{{ __('사업자번호', 'Register No.') }}</td>
              <td>130-87-01811</td>
              <td class="col-head">{{ __('상호', 'Name') }}</td>
              <td>{{ __('(주)제이케이그룹', 'JK Group') }}</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('전화번호', 'Phone') }}</td>
              <td>{{ __('1800-8914', '+82-32-674-8914') }}</td>
              <td class="col-head">{{ __('대표자', 'Owner') }}</td>
              <td>{{ __('김 경 철 (인)', 'Evans Kim') }}</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('업태', 'Type') }}</td>
              <td>{{ __('도매 및 소매업', 'Wholesale') }}</td>
              <td class="col-head">{{ __('업종', 'Items') }}</td>
              <td>{{ __('캔들,용기', 'Home Fragrance Materials') }}</td>
            </tr>
            <tr>
              <td class="col-head">{{ __('주소', 'Address') }}</td>
              <td colspan="3">{{
                  __('경기도 부천시 산업로 124 2층', '2F Candleworks bldg, Saneup-ro 124, Bucheon-si, Gyeunggi-do, South Korean')
                }}
              </td>
            </tr>
          </table>
          <template v-else>
            <table class="column-table">
              <tr>
                <td colspan="4">
                  Candleworks ( JK Group., Ltd. )
                </td>
              </tr>
              <tr>
                <td class="col-head">Phone</td>
                <td>+82-32-674-8914</td>
                <td class="col-head">Fax</td>
                <td>+82-32-672-8914</td>
              </tr>
              <tr>
                <td class="col-head">{{ __('주소', 'Address') }}</td>
                <td colspan="3">{{
                    __('경기도 부천시 산업로 124 2층', '2F Candleworks bldg, Saneup-ro 124, Bucheon-si, Gyeunggi-do, Republic of Korea')
                  }}
                </td>
              </tr>
            </table>
          </template>
        </evans-box>
      </el-col>
    </el-row>
    <evans-box :title="__('주문내역','Products')">
      <order-invoice-items-table :items="order.items" :currency="order.od_currency"
                                 :order-id="order.od_id"></order-invoice-items-table>
    </evans-box>
    <el-row :gutter="20">
      <el-col :span="12">
        <evans-box :title="__('고객 메시지','Customer Message')">
          <ev-textarea v-model="order.od_memo"></ev-textarea>
          <ev-button @click="saveComments">저장</ev-button>
        </evans-box>
      </el-col>
      <el-col :span="12">
        <evans-box :title=" __('관리자 메모','Memo') ">
          <ev-textarea v-model="order.od_comment"></ev-textarea>
          <ev-button @click="saveComments">저장</ev-button>
        </evans-box>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :span="16">
        <el-row>
          <evans-box :title="__('배송정보','Shipment')">
            <table class="column-table">
              <tr>
                <td class="col-head">{{ __('택배운송사', 'Courier') }}</td>
                <td>
                  {{ showDeliveryName(order) }}
                  <span v-show="order.od_box_qty">[ {{ order.od_box_qty }} {{ __('박스', 'Boxes') }} ]</span>
                </td>
                <td class="col-head">{{ __('수령자명', 'Recipient Name') }}</td>
                <td>{{ order.od_b_name }}</td>
              </tr>
              <tr>
                <td class="col-head">
                  {{ __('전화번호', 'Telephone') }}
                </td>
                <td>{{ order.od_b_tel }}</td>
                <td class="col-head">{{ __('핸드폰', 'Mobile Phone') }}</td>
                <td>{{ order.od_b_hp }}</td>
              </tr>
              <tr>
                <td class="col-head">{{ __('주소', 'Address') }}</td>
                <td>
                  ( {{ order.od_b_zipcode }} )
                  <el-tag v-if="order.od_b_country &&  order.od_b_country !== 'KR'">{{ order.od_b_country }}</el-tag>
                  {{ order.od_b_addr1 }}
                </td>
                <td class="col-head">{{ __('상세주소', 'Street') }}</td>
                <td>{{ order.od_b_addr2 }}</td>
              </tr>
              <tr>
                <td class="col-head">{{ __('배송메시지', 'Message to Courier') }}</td>
                <td colspan="3">

                  <ev-textarea v-model="order.od_delivery_memo"></ev-textarea>
                  <ev-button @click="saveComments">저장</ev-button>

                </td>
              </tr>
            </table>
          </evans-box>
          <evans-box v-if="!!order.od_status" :title="__('배송현황', 'Shipment Status')">
            <h6 v-if=" isUnpaid ">{{ __('고객님의 결제를 기다리고 있습니다.', 'We are waiting your payment.') }}</h6>
            <h6 v-if=" isPaid && !isShipped ">{{
                __('캔들웍스에서 고객님의 상품을 준비하고 있습니다.', 'We are preparing your order.')
              }}</h6>
            <h6 v-if="isShipped && order.delivery"> {{ __('운송사', 'Currier') }} : {{ order.delivery.dl_company }}</h6>
            <h6 v-if="isShipped && order.od_invoice"> {{ __('대표송장', 'Tracking Invoice') }} : {{ order.od_invoice }}</h6>
            <div v-if="isShipped && order.shipments && order.shipments.length > 0">
              <h6>{{ __('송장번호 안내', 'Tracking Number(s)') }}</h6>
              <span class="border rounded p-2 cursor-pointer bg-white my-2 inline-flex "
                    v-for="(invoice, i) in order.shipments" :key="i">
                  {{ invoice.sp_invoice }}
                </span>
            </div>
          </evans-box>
        </el-row>
        <evans-box :title="__('안내말씀','Notice')">
          <p style="white-space: pre-line; font-size: 13px">단순 반품을 원하시는 경우, 수령 후 7일 이내 전화로 반품 신청을 해
            주세요.(1800-8914)
            단순 반품으로 구매 금액 합계가 무료배송 금액 보다 낮은 경우 왕복 택배비용이 발생합니다.
            배송 중에 파손된 상품을 발견 시에는 바로 사진을 찍어 주세요. 배송회사 측에 파손배상을 요구하기 위해 꼭 필요합니다.
            파손된 상품의 반품 및 교환은 7일 이내에 가능하며, 이후에 파손 신고된 상품은 반품요청이 수리되지 않습니다.
            7일 이내에 파손된 상품이라도 고객의 부주의로 파손된 상품은 반품요청이 수리되지 않습니다.
            <b>프래그런스, 천연 에센셜 오일, 조향원료 베이스는 고객의 주문과 즉시 소분 생산되므로 사용하지 않으셨더라도 반품 및 교환이 불가합니다.</b>
          </p>
        </evans-box>
      </el-col>
      <el-col :xs="24" :span="8">
        <evans-box :title="__('결제정보','Payment')">
          <table class="column-table">
            <tr>
              <td class="payment-col-head">{{ __('결제수단', 'Payment Method') }}</td>
              <td>{{ order.od_settle_case }}</td>
            </tr>
            <tr v-if="order.od_settle_case === '무통장'">
              <td class="payment-col-head">{{ __('입금계좌', 'Bank Account') }}</td>
              <td>{{ order.od_bank_account }}</td>
            </tr>
            <tr>
              <td class="payment-col-head">{{ __('상품합계', 'Item Total') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ summarise | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr>
              <td class="payment-col-head">{{ __('배송비', 'Shipping Cost') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_send_cost + order.od_exdelivery | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr v-show="!!order.od_dc_amount">
              <td class="payment-col-head">{{ __('할인', 'Discounts') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_dc_amount | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr v-if="order.od_receipt_point > 0">
              <td class="payment-col-head">{{ __('적립금사용', 'Used Credits') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_receipt_point | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr>
              <td class="payment-col-head">{{ __('주문총액', 'Total amount') }}</td>
              <td><span style="font-size: larger"><currency-icon
                  :fix="order.od_currency"/> {{ getDueToPay | formatNumber(order.od_currency) }}</span>
              </td>
            </tr>
            <tr>
              <td class="payment-col-head">{{ __('결제금액', 'Paid Amount') }}</td>
              <td><span style="font-size: larger"><currency-icon
                  :fix="order.od_currency"/> {{ order.paid_amount | formatNumber(order.od_currency) }}</span>
              </td>
            </tr>

          </table>
        </evans-box>

        <evans-box :title="__('환불정보','Refund')" v-if="isRefunded">
          <table class="column-table">
            <tr v-show="!!order.od_refund_amount">
              <td class="payment-col-head">{{ __('환불액', 'Refund amount') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_refund_amount | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr v-show="!!order.od_cancel_card">
              <td class="payment-col-head">{{ __('카드취소', 'Canceled Credit Card') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_cancel_card | formatNumber(order.od_currency) }}
              </td>
            </tr>
            <tr v-show="!!order.od_refund_cash">
              <td class="payment-col-head">{{ __('적립금환불', 'Credit Refund') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.od_refund_cash | formatNumber(order.od_currency) }}
              </td>
            </tr>
          </table>
        </evans-box>
        <evans-box v-if="order.coupon_issue" :title="__('쿠폰할인','Coupon')">
          {{ __('주문총액은 할인이 반영된 금액입니다.', 'Total Amount has been discounted already.') }}
          <table class="column-table">
            <tr>
              <td class="payment-col-head">{{ __('사용된 쿠폰', 'Used Coupon') }}</td>
              <td> {{ __(order.coupon_issue.coupon.name, order.coupon_issue.coupon.name_en) }}</td>
            </tr>
            <tr>
              <td class="payment-col-head">{{ __('할인금액', 'Discount amount') }}</td>
              <td>
                <currency-icon :fix="order.od_currency"/>
                {{ order.coupon_issue.discounted_amount | formatNumber(order.od_currency) }}
              </td>
            </tr>
          </table>
        </evans-box>
      </el-col>
    </el-row>
    <div v-html="paymentForm"></div>
  </el-card>
</template>

<script>

import CurrencyIcon from "./CurrencyIcon";
import OrderInvoiceItemsTable from "./OrderInvoiceItemsTable";
import EvansBox from "@/components/EvansBox";
import axios from "@/axiosConfig";
import {updatePurchaseData} from "@/apis/purchase";

export default {
  name: "OrderInvoice",
  components: {
    EvansBox,
    OrderInvoiceItemsTable,
    CurrencyIcon,

  },
  data() {
    return {
      loading: true,
      order: {
        od_id: '',
        items: [],
        od_is_mb: 0,
        documents: [],
        kcp_receipt: {}
      },
      paymentForm: '',

    }
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    printable: {
      type: Boolean,
      default: true
    },
    isDocument: Boolean,
    currency: {
      type: String,
      default: 'krw'
    },
    storeId: null
  },
  methods: {
    isEmsDelivery(order) {
      return order.dl_id == 3 || order.dl_id == 6;
    },
    __(ko, en) {
      if (!this.order.od_id) {
        return ko;
      }
      if (this.order.od_currency === 'usd') {
        return en;
      } else {
        return ko;
      }
    },
    showDeliveryName(order) {
      return (order && order.delivery) ? order.delivery.dl_company : '';
    },
    getReceiptType(order) {
      if (order.od_is_mb === 1) {
        return '세금계산서'
      }
      return order.od_cash_type;
    },
    doPaymentKCP() {
      if (this.order.od_settle_case === '신용카드') {
        doPayment();
      }
    },
    getOrder() {
      axios.get(this.getApiUrl).then((res) => {
        this.order = res.data;
        this.loading = false;
        this.$emit('loaded', this.order);
      });
    },
    cancelOrder() {
      this.$confirm(__('정말로 주문을 취소하시겠습니까? 취소된 주문은 복구할 수 없습니다.', 'This post will be deleted permanently. Do you want to continue'), 'Warning', {
        confirmButtonText: __('예, 삭제합니다.', 'Yes, delete it.'),
        cancelButtonText: __('아니요.', 'No, I don`t.'),
        type: 'warning'
      }).then(() => {
        axios.delete(this.getApiUrl).then((res) => {
          if (res.data.message) {
            this.$message.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: __('취소되었습니다.', 'Canceled')
        });
      });

    },
    getPaymentForm() {

      axios.get("/api/v1/kcp/get-payment-form?od_id=" + this.orderId).then((res) => {
        this.paymentForm = res.data.form;
      })
    },
    getImageUrl(it_id) {
      return "https://www.candleworks.co.kr/data/item/" + it_id + "_s";
    },
    getMemberGroup(order) {
      if (!order.member) {
        return '비회원';
      }
      if (order.member.mb_id === 'admin2' || order.member.mb_id === 'some' || order.member.mb_id === 'admin') {
        return '';
      }
      return order.member.my_group;
    },
    goToReceive() {
      this.$router.push({name: 'PurchaseReceive', params: {
          orderId: this.orderId
      }})
    },
    async saveComments(data) {
      const res = await updatePurchaseData(this.order.od_id, this.order);
      this.$message.success('저장되었습니다.');
    }
  },
  computed: {
    paidBalance() {
      return this.order.paid_amount - this.order.od_refund_amount - this.order.od_refund_cash - this.order.od_refund_card;
    },
    isKorean() {
      return this.order.od_currency !== 'usd';
    },
    getApiUrl() {
      var url = "/api/v1/resource/order/" + this.orderId;
      if (this.storeId)
        url += '?store_id=' + this.storeId;

      return url;
    },
    trackingURL() {
      if (!this.order.od_invoice) {
        return false
      }
      if (!this.order.delivery)
        return '';

      return this.order.delivery.dl_url + this.order.od_invoice;
    },
    isCash() {
      return this.order && this.order.od_settle_case === '무통장';
    },
    isCreditCard() {
      return this.order && this.order.od_settle_case === '신용카드';
    },
    isPayPal() {
      return this.order && this.order.od_settle_case && this.order.od_settle_case.toLowerCase() === 'paypal';
    },
    isPrintable() {
      if (this.isDocument)
        return {header: true, footer: true};
      else
        return {header: false, footer: false}
    },
    isReceiptIssued() {
      return this.order.od_is_mb === 3;
    },
    isCanceled() {
      return this.order.od_status === '취소';
    },
    isUnpaid() {
      return this.order.od_status === '주문';
    },
    isPaid() {
      return this.order.paid_amount === this.order.due_to_pay;
    },
    isShipped() {
      return this.order.od_invoice || _.includes(['배송', '완료'], this.order.od_status);
    },
    isRefunded() {
      return this.order.od_refund_amount +
          this.order.od_cancel_card +
          this.order.od_refund_cash > 0;
    },
    getDueToPay() {
      return this.summarise + (this.order.od_send_cost + this.order.od_exdelivery) - this.order.od_receipt_point - this.order.od_dc_amount;
    },
    getOrderItems() {
      return this.order.items;
    },
    OrderId() {
      return this.orderId;
    },
    summarise() {
      let sum = Math.round(_.sumBy(this.order.items, function (item) {
        if (item.ct_is_son) {
          return 0
        }
        return item.ct_qty * item.ct_amount
      }) * 100) / 100;

      return sum;
    },
  },
  mounted() {
    this.getOrder();

  }
}
</script>
<style lang="scss" scoped>


.ceo-stamp {
  position: absolute;
  width: 70px;
  top: -42px;
  left: 42px;
  opacity: 0.8;
}

.hidden-print {
  @media print {
    display: none;
  }
}

.pc-only {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.el-card__body {
  padding: 0px;
}

.table-responsive {
  @media screen and (max-width: 768px) {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
}

.children-table {
  border: 1px solid #e1e2e3;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 50px;
  min-height: 200px;
}

.table-footer {
  padding: 15px;
  padding-top: 0px;
}

.text-center {
  text-align: center;
}

.header-cell {
  text-align: center;
}

.el-table th {
  text-align: center;
}

.price-column {
  text-align: right;
  font-size: 18px;
}

.table-summary {
  text-align: right;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 18px;
  clear: both;
  height: 60px;
}

.column-table {
  width: 100%;

  .title {
    margin-left: 6px;
  }

  tr {
    border-bottom: 1px solid #e1e2e3;

    .payment-col-head {
      width: 40%;
    }

    td {
      padding: 6px 8px;
      min-width: 100px;
      max-width: 300px;
    }

    .col-head {
      min-width: 100px;
    }
  }

  tr:last-child {
    border-bottom: none !important;
  }
}
</style>
