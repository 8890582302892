<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <datepicker ref="picker"
                :input-class="getClasses"
                :value="value"
                :format="format"
                :language="ko"
                :highlighted="getFocusedDates"
                :disabled-dates="disabledDates"
                @input="inputHandler"
                :disabled="disabled"
    ></datepicker>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {ko} from 'vuejs-datepicker/dist/locale'

    export default {
        name: "EvDatepicker",
        components: {
            Datepicker
        },
        props:{
            value: null,
            format:{
                default:'yyyy-MM-dd'
            },
            timeable : {
                default : false
            },
            startDate: { type : Date },
            endDate: { type: Date },
            disabled:{ type: Boolean}
        },
        // setup(){
        //     return {ko};
        // },
        data() {
            return {
                /**
                 * moment
                 */
                date:null,
                hour:'',
                min:'',
                calendar : {},
                isChanged: false,
                invalid: false,
                focused : false,
                ko: ko,
            }
        },
        computed: {
            getInputType() {
                return (this.$attrs['type']) ? this.$attrs['type'] : 'text';
            },
            hasErrorField() {
                return this.$parent.hasError;
            },
            getClasses() {
                let mode = 'default';
                if (this.hasErrorField) {
                    mode = 'error';
                }
                let typeClasses = [];
                switch (mode) {
                    case 'default':
                        typeClasses = [
                            "w-full",
                            "border",
                            "border-main-600",
                            "appearance-none",
                            "py-2",
                            "px-4",
                            "leading-tight",
                            "focus:outline-none",
                            "transition-ease",
                            "h-full"
                        ];
                        break;
                    case 'error' :
                        typeClasses = [
                            "bg-red-200",
                            "text-red-700",
                            "focus:bg-red-100",
                            "focus:border-red-300",
                        ];
                        break;
                }
                if (this.$attrs['type'] === 'password') {
                    typeClasses.push('ime-inactive');
                }

                return typeClasses.concat(this.classes);
            },
            picker(){
                return this.$refs['picker']
            },
            getFocusedDates() {
                return { dates: [ (this.value) ? new Date(this.value) : new Date() ]}
            },
            disabledDates() {
                let range = {}

                if(this.startDate){
                    range.to = this.startDate
                }

                if(this.endDate){
                   range.from = this.endDate
                }

                return range
            }
        },
        methods: {
            inputHandler(e) {
                this.$emit('input', this.picker.utils.formatDate(e, this.format));
            }
        }
    }
</script>
