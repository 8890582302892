<template>
    <ev-remote-select
            ref="selector"
            api-url="/api/v1/resource/price?it_name="
            :value="getValue"
            @input="handler"
            :single="!multiple"
    >
        <template v-slot:option="prop">
            {{ prop.item.product.it_name }}[ {{prop.item.op_name}} ]
        </template>
    </ev-remote-select>
</template>

<script>
    import EvRemoteSelect from "../components/EvRemoteSelect";

    /**
     * 가격정보 검색하고 선택하여 객체로 받습니다.
     * @emit input for v-model
     */
    export default {
        name: "EvPriceSelector",
        components: {EvRemoteSelect},
        data(){
            return{
                mutate:{}
            }
        },
        props:{
            /**
             * @v-model
             */
            value:null,
            /**
             * 가격정보 중에 라벨로 출력할 필드
             */
            valueKey:{
                default : null
            },
            /**
             * 다중선택여
             */
            multiple :{
                type : Boolean
            }
        },
        methods: {
            handler(val) {
                this.mutate = val;

                if(!val){
                    this.$emit('input', null);
                    return;
                }

                if(this.valueKey){
                    this.$emit('input', val[this.valueKey]);
                    return;
                }

                if(!this.multiple){
                    this.$emit('input',  this.getCleanData(val));
                    return;
                }

                let data = val.map((item)=>{
                    return this.getCleanData(item)
                });

                this.$emit('input', data);
            },
            getCleanData(item)
            {
                return {
                    'op_id' : item.op_id,
                    'op_name' : item.op_name,
                    op_weight : item.op_weight,
                    product : {
                        it_name : item.product.it_name
                    }
                };
            },
        },
        computed: {
            getValue() {

                if(this.valueKey && this.mutate && this.mutate.hasOwnProperty(this.valueKey)){
                    return this.mutate;
                }

                return this.value;
            }
        }
    }
</script>

<style scoped>

</style>
