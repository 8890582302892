<template>
  <div class="absolute bg-white rounded p-2 shadow-md border" v-if="hasSelectives">
    <h6> 옵션선택 </h6>
    <div v-for=" (options, key) in getSelectives">
      <ev-select v-model="selectedOptions[key]" :key="key + 'option'"
                 :single="false"
                 :max="1"
                 :options="options"
                 :label-key="price.product.it_name"
                 :placeholder=" options[0].selective + ' 선택'" style="width: 100%"
      >
        <template v-slot:option="prop">
          <select-option-label :item="prop.item"></select-option-label>
        </template>
        <template v-slot:result="prop">
          <select-option-label :item="prop.item"></select-option-label>
        </template>
      </ev-select>
    </div>
  </div>
</template>

<script>
import Product from "@/evans/controls/cartables/Product";
import SelectOptionLabel from "@/evans/controls/cartables/SelectOptionLabel";

export default {
  name: "SetProduct",
  components: {SelectOptionLabel},
  extends: Product,
  data() {
    return {
      selectedOptions: {}
    }
  },
  watch: {

    isSelectedDone(val) {
      if (val === true) {
        this.send();
      }
    }
  },
  computed: {
    isSelectedDone() {
      return this.getSelectives.length === Object.keys(this.selectedOptions).filter((item) => {
        return item.length
      }).length
    },
    hasSelectives() {
      return this.product.children_group && this.product.children_group.options;
    },
    getSelectives() {
      if (this.hasSelectives) {
        return this.product.children_group.options.map((list) => {
          return list.sort((a, b) => {
            return a.sp_order - b.sp_order
          });
        });
      }
      return [];
    },
  },
  methods: {
    async send() {
      this.setupParams();
      const res = await this.postProduct();

    },
    setupParams() {
      this.params.op_id = this.price.op_id;
      this.params.it_id = this.price.it_id;
      this.params.is_gift = this.isGift;
      for (let i in this.selectedOptions) {
        this.params.options.push(this.selectedOptions[i][0].uid);
      }
    },
    async handle() {
      await this.getProduct();

      if(this.isSelectedDone){
        await this.send();
      }

    }
  }
}
</script>

<style scoped>

</style>
