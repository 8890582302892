<template>
  <div>
    <add-purchase-cart @created="()=>{ $refs['list'].getItems()  }"/>
    <purchase-cart-list ref="list"/>
  </div>
</template>

<script>
import AddPurchaseCart from "@/controls/purchase/AddPurchaseCart";
import PurchaseCartList from "@/controls/purchase/PurchaseCartList";

export default {
  name: "PurchaseCreate",
  components: {PurchaseCartList, AddPurchaseCart},
  data() {
    return {
      showTodaySales: false
    }
  }
}
</script>

<style scoped>

</style>
