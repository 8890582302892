<template>
  <div class="flex justify-center items-center">
    <ev-form v-if="$store.getters['user/user'] === false"
             v-model="auth"
             :errors="errors"
             @submit="login"
             class="max-w-lg"
    >
      <ev-form-field label="아이디" field="mb_id" class="w-full"/>
      <ev-form-field label="비밀번호" field="mb_password" type="password" class="w-full"/>
      <ev-form-field label="자동로그인" field="auto" type="checkbox" class="w-1/6 text-left"/>
      <ev-form-field class="w-full">
        <ev-button class="w-full"
                   type="primary"
                   @click="login"
        >로그인</ev-button>
      </ev-form-field>

    </ev-form>
    <div v-else>
      이미 로그인 됨
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data(){
      return {
        auth: {
          mb_id: '',
          mb_password: '',
        },
        errors: {
          mb_id : ['아이디를 입력하세요.']
        }
      }
    },
    methods: {
      async login(){

        this.errors = {}
        try {
          const res = await this.$store.dispatch('user/login', {
            id : this.auth.mb_id,
            password : this.auth.mb_password
          })
        }catch (error){
          const res = error.response;

          this.errors = res.data;

        }

      }
    },
  }
</script>