<template>
  <div class="order-items-table-div">
    <table class="order-items-table">
      <thead>
      <th class="index" v-if="selective">
        <ev-check-box :value="selectedAll" @input="toggleAllSelectives" class="text-center"/>
      </th>
      <th v-else> #</th>
      <th>상태</th>
      <th>상품명</th>
      <th>단위</th>
      <th>가격</th>
      <th>수량</th>
      <th>소계</th>
      <th>위치</th>
      </thead>
      <tbody>
      <template v-for="(item, index) in getItemsOrderByArea(items)">
        <tr class="cart-row">
          <td class="index" v-if="selective">
            <ev-check-box :value="getSelective(item)" @input="(val)=>setSelective(item, val)" class="text-center"/>
          </td>
          <td class="index" v-else>{{ index + 1 }}</td>
          <td class="index">
            <order-item-status-tag style="display: block" :status="item.ct_status"
                                   :lang="(currency === 'usd' ) ? 'en':'kr'"/>
          </td>
          <td class="item-name">
            <div class="flex fle-1 flex-no-wrap">
              <div class="flex-shrink-0" style="min-width: 60px">
                <ratio-img :src="getImageUrl(item)" :width="50" vertical="top"></ratio-img>
              </div>
              <div class="flex items-center flex-wrap">
                <div contenteditable="true" style="font-size: 16px" class="w-full">

                  {{ item.op_itname }}

                </div>
                <div v-if="item.product.it_alert" class="w-full">{{ item.product.it_alert }}</div>
                <slot name="item-detail" :item="item" class="w-full"></slot>

              </div>
            </div>
          </td>
          <td class="currency" style="font-size: 17px; font-weight: 600;">
            <div>{{ item.op_name }}</div>
          </td>
          <td class="currency">
            <div v-if="!selective">
              <currency-icon :fix="currency"/>
              {{ item.ct_amount  | formatInt(currency) }}
            </div>
            <ev-input v-else type="number" :key="item.ct_id + '_amount'" :value="item.ct_amount"
                      @keyup.enter="()=>{submitItem(item)}" @input="(val)=>{updateItem(item, 'ct_amount', val)}"
                      diff-check class="text-right max-w-sm"></ev-input>
          </td>
          <td class="currency" style="font-size: 17px; font-weight: 600;">
            <span v-if="!selective">{{ item.ct_qty  | formatInt(currency) }} </span>
            <ev-input v-else type="number" :key="item.ct_id + '_ct_qty'" :value="item.ct_qty"
                      @keyup.enter="()=>{submitItem(item)}" @input="(val)=>{updateItem(item, 'ct_qty', val)}" diff-check
                      class="text-right max-w-sm"/>
          </td>
          <td class="currency">
            <currency-icon :fix="currency"/>
            {{ item.ct_qty * item.ct_amount | formatInt(currency) }}
            <div v-if="getDiscounted(item)" class="text-gray-600 font-thin line-through">
              {{ getDiscounted(item) | formatInt(currency) }}
            </div>
          </td>
          <td class="currency">
            <span v-html="item.product.it_area"></span>
          </td>
        </tr>
        <template v-if="hasChildren(item)">
          <tr class="cart-row children-row" v-for="child in getChild(item)">
            <td class="index">{{ __('포함', 'Included') }}</td>
            <td class="index">
              <order-item-status-tag :status="child.ct_status" :lang="(currency === 'usd' ) ? 'en':'kr'"/>
            </td>
            <td class="item-name">
              <el-row type="flex">
                <el-col style="flex-shrink: 0; width: 60px; padding-right: 10px;">
                  <ratio-img :src="getImageUrl(child)" :width="50"></ratio-img>
                </el-col>
                <el-col>
                  <div style="font-size: 18px">{{ child.op_itname }}</div>
                </el-col>
              </el-row>
            </td>
            <td class="currency" style="font-size: 16px; font-weight: 600;"> {{ child.op_name }}</td>
            <td class="currency">{{ __('포함', 'Included') }}</td>
            <td class="currency">{{ child.ct_qty  | formatInt(currency) }}</td>
            <td class="currency"></td>
            <td class="currency">
              <span v-html="child.product.it_area"></span>
            </td>
          </tr>
        </template>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import OrderItemStatusTag from "./OrderItemStatusTag";
import CurrencyIcon from "./CurrencyIcon";
import RatioImg from "./RatioImg";

export default {
  name: "OrderInvoiceItemsTable",
  components: {RatioImg, CurrencyIcon, OrderItemStatusTag},
  filters: {
    formantNumber() {

    },
    formatInt(val) {
      let string = parseInt(val).toLocaleString();
      let result = '';
      for (const i in string) {
        if (!i % 3) {
          result += string[i] + ','
        } else {
          result += string[i]
        }
      }
      return result;
    }
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    storeId: null,
    orderId: {
      type: String,
      default: ''
    },
    currency: null,
    selective: {
      type: Boolean
    }
  },
  computed: {
    isKorean() {
      return this.currency !== 'usd';
    },
    summarise() {
      let sum = _.sumBy(this.items, function (item) {
        return item.ct_qty * item.ct_amount
      });

      return sum;
    }
  },
  methods: {
    __(korean, english) {
      if (this.isKorean)
        return korean;
      else
        return english;
    },
    getItemsOrderByArea(items) {
      return _.sortBy(items, (item) => {
        return item.product.it_area;
      });
    },
    getChild(item) {
      return item.children.filter((child) => {
        return !this.isHidden(item, child);
      });
    },
    hasChildren(item) {
      return item.children && item.children.length > 0;
    },
    isDIYProduct: function (parent) {
      return parent.product.ca_id.match(/^2001/);
    },
    isSelectedItem: function (parent, child) {
      var ids = parent.selected_options.map((it) => {
        if (it.hasOwnProperty('uid')) {
          return it.uid;
        } else {
          return it;
        }
      });
      return _.includes(ids, child.is_matched);
    },
    isHidden(parent, child) {
      if ((!parent || !parent.product.ca_id) || !child)
        return false;
      if (this.isDIYProduct(parent)) {
        return false; // diy 상품은 모두 출력
      } else {
        return !this.isSelectedItem(parent, child); // 그 외 상품은 선택상품만
      }
    },
    getOrder() {
      axios.get("/api/v1/my-order/" + this.orderId).then((res) => {
        this.items = res.data.items;
        this.loading = false;
      }, (error) => {
        const msg = res.response;
        this.$message.error(msg.data.message);
      });
    },
    getImageUrl(item) {
      return item.product.thumb_image + '@w50';
    },
    getSelective(item) {
      if (!item.hasOwnProperty('__is_selected')) {
        this.$set(item, '__is_selected', false);
      }
      return item.__is_selected;
    },
    setSelective(item, val) {
      if (!item.hasOwnProperty('__is_selected')) {
        this.$set(item, '__is_selected', val);
      } else {
        item.__is_selected = val;
      }
    },
    toggleAllSelectives(val) {

      this.items.map(item => {
        return item.__is_selected = val;
      })
    },
    updateItem(item, key, val) {

      item[key] = val;

      this.items.filter(it => {
        return it.ct_id === item.ct_id
      }).map((it) => {
        it[key] = val;
      });
    },
    submitItem(item) {
      this.setSelective(item, true);
      this.$emit('updated', item);

    },
    getDiscounted(item) {
      if (!item || !item.ct_history) {
        return 0;
      }
      try {
        let history = JSON.parse(item.ct_history);
      } catch (e) {
        let history = null
      }
      if (history && history.discounted) {
        return (parseFloat(history.discounted) + parseFloat(item.ct_amount)) * parseFloat(item.ct_qty);
      }
      return 0;
    }
  },
  data() {
    return {
      selectedAll: false
    }
  }
}
</script>

<style lang="scss" scoped>

.hidden-print {
  @media print {
    display: none;
  }
}

.order-items-table-div {
  overflow-x: auto;
  @media screen {
    overflow: hidden;
  }
}

.order-items-table {
  .children-row {
    background-color: #e1e2e3;
  }

  width: 100%;
  //border-top: 3px solid #000000;
  th {
    font-weight: 500;
    text-align: center;
    padding: 10px 0px;
  }

  tr {
    border-bottom: 1px solid #e1e2e3;

    td {
      padding: 4px 6px;
    }
  }

  tr:first-child {
    border-top: 1px solid #e1e2e3;
  }

  .index {
    min-width: 30px;
    text-align: center;
  }

  .item-name {
    min-width: 300px;
  }

  .option-name {
    min-width: 150px;
    text-align: center;
  }

  .currency {
    min-width: 110px;
    text-align: center;
    font-size: 15px;
  }

  .grand-total {
    border-bottom: none;
    text-align: right;
    font-weight: 500;
    font-size: 18px;
  }
}

.label-message {
  font-size: 13px;
}
</style>
