var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tab-list flex items-center"},_vm._l((_vm.tabList),function(tab){return _c('div',{staticClass:"tab-item cursor-pointer",class:[
           _vm.tabStyle,
           _vm.tabSelected === tab.value? _vm.selectedStyle : _vm.hoverStyle,
         ],style:({
           transitionDuration: _vm.transitionDuration+'ms',
           transitionProperty: _vm.transitionProperty
         }),on:{"click":function($event){return _vm.setTab(tab.value)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0),(_vm.separator)?_c('div',{staticClass:"tab-separator"},[_vm._t("separator",function(){return [_c('hr',{staticClass:"my-2"})]})],2):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }