import Vue from 'vue'
import App from './App.vue'
import router from './router'
const store = require('./store').default
import './assets/tailwind.css'

import EvansUI from './evans'
Vue.use(EvansUI);
import EvMessage from './evans/plugins/EvToast';
Vue.use(EvMessage);
import SequencePlugIn from "./evans/plugins/Sequence";
Vue.use(SequencePlugIn);
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: 'rgb(127, 192, 133)',
  failedColor: 'red',
  thickness: '4px'
})

import ElementUI from 'element-ui';
import "@/../node_modules/element-ui/packages/theme-chalk/src/index.scss";
import lang from 'element-ui/lib/locale/lang/ko'
import locale from 'element-ui/lib/locale'
locale.use(lang)
Vue.use(ElementUI)

Vue.prototype.$config = {
  get : (field)=>{
    return store.getters['config/pos'][field]
  },
  check :(key)=>{
    return (new ConfigStore()).check(key);
  }
};


import Treeselect from '@riophae/vue-treeselect'
import '@/../node_modules/@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('Treeselect', Treeselect)

Vue.config.productionTip = false
import axios from "./axiosConfig";
import ConfigStore from "@/plugins/ConfigStore";


Vue.prototype.axios = axios;


Vue.filter("formatInt", function (value) {
  let string = parseInt(value).toLocaleString();
  let result = '';
  for (const i in string) {
    if( !i % 3 ){
      result += string[i] + ','
    }else{
      result += string[i]
    }
  }
  return result;
});
Vue.filter("formatNumber", function (value, currency) {
  let string = parseInt(value).toLocaleString();
  let result = '';
  for (const i in string) {
    if( !i % 3 ){
      result += string[i] + ','
    }else{
      result += string[i]
    }
  }
  return result;
});

Vue.mixin({
  methods: {
    __(ko,en){
      return ko;
    }
  }
});

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
