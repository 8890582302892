import {ConfirmModel} from "@/evans/plugins/EvConfirm";
import {messenger} from "@/evans/plugins/EvToast";
import axios from "@/axiosConfig";

function OrderModel() {
    this.order = {};
    this.$confirm = (new ConfirmModel()).$confirm;
    this.$message = messenger;
    this.updateAddress = (data) => {
        this.order.od_b_addr1 = data.address;
    }
    this.checkSelectedItems = ()=>{
        let filter = this.order.items.filter(item => {
            return item.__is_selected
        });
        if (!filter.length) {
            this.$message.warning('선택된 상품이 없습니다.');
            throw 'Nope';
        }
        return filter;
    }
    this.cancelCredits = ()=>{
        this.$confirm('적립금 환불 및 사용 내역이 모두 삭제 됩니다.').then(
            async (res) => {
                await this.callOrderUpdaterApi(
                    'App\\Actions\\Order\\OrderUpdateCreditCancel',
                    {
                        od_id: this.orderId,
                        confirmation: true
                    }
                );

                this.getOrder();
            }
        )
    }
    this.updateReceiptInfo = ()=>{

        this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdatePaymentData',
            {
                od_id: this.orderId,
                od_settle_case: this.order.od_settle_case,
                od_bank_account: this.order.od_bank_account,
                od_is_mb: this.order.od_is_mb,
                od_cash_type: this.order.od_cash_type,
                od_cash_num: this.order.od_cash_num,
                mb_cname: this.order.mb_cname,
                mb_cnum: this.order.mb_cnum,
                mb_cceo: this.order.mb_cceo,
                mb_cpart1: this.order.mb_cpart1,
                mb_cpart2: this.order.mb_cpart2,
                mb_cemail: this.order.mb_cemail,
                mb_caddr: this.order.mb_caddr,
            }
        )

    }
    this.getCountCheckTime = (item)=>{
        let lgDatetime = this.filteredCheck(item)[0].lg_datetime;
        if(!lgDatetime){
            return '';
        }
        this.$set(item, 'count_time', lgDatetime);
        return item.count_time;
    }
    this.filteredCheck = (item)=>{
        return this.order.checks.filter(it => {
            return item.op_id === it.op_id
        });
    }
    this.hasCountCheck = (item)=>{
        if( !this.order.checks || !this.order.checks.length ){
            return false;
        }
        if(this.filteredCheck(item).length === 0){
            return false;
        }
        return true;
    }
    this.showReceipt = (order)=>{
        try{
            showOrderReceipt(order);
        }catch (e) {
            
            this.$message.warning(e.message);
        }
    }
    this.deleteItem = async () => {
        let filter = this.order.items.filter(item => {
            return item.__is_selected
        });
        if (!filter.length) {
            this.$message.warning('선택된 상품이 없습니다.');
            return;
        }
        this.$confirm(filter.length + "개의 상품을 삭제할까요? 삭제된 상품은 복구 할 수 없습니다.").then(
            async () => {
                await this.$progress(
                    filter,
                    async (item) => {
                        await axios.post("/api/v1/resource/order-updater",
                            {
                                class_name: 'App\\Actions\\Order\\OrderUpdateItemDelete',
                                parameters: {
                                    od_id: this.order.od_id,
                                    ct_id: item.ct_id
                                }
                            }).then(res => {

                            this.$message.success(res.data.message);

                        }, err => {
                            const res = err.response;
                            this.errors = res.data;
                        });
                    }
                );
                this.getOrder();

            }
        )
    },
    this.copyItem = async () => {
        let selected = this.checkSelectedItems();

        this.$progress(
            selected,
            async (item) => {
                await this.callOrderUpdaterApi(
                    'App\\Actions\\Order\\OrderUpdateItemCopy',
                    {
                        od_id: this.orderId,
                        ct_id: item.ct_id
                    }
                );
                this.getOrder();
            }
        )
    },
    this.updateOrderItemsPriceByGroup = async () => {
        this.$confirm(this.order.mb_level + " 등급으로 상품가격을 변경할까요?").then(
            () => {
                axios.post("/api/v1/resource/order-updater",
                    {
                        class_name: 'App\\Actions\\Order\\OrderUpdateItemPriceByMember',
                        parameters: {
                            od_id: this.order.od_id,
                            mb_level: this.order.mb_level
                        }
                    }).then(res => {
                    this.$message.success(res.data.message);
                    this.getOrder();
                }, err => {
                    const res = err.response;
                    this.errors = res.data;
                });
            }
        )
    },
    this.deleteOrder = async () => {
        this.$confirm('삭제하시겠습니까?').then(
            async () => {
                await this.callOrderUpdaterApi(
                    'App\\Actions\\Order\\OrderUpdateDeleteAll',
                    {
                        od_id: this.orderId,
                        confirmation: true
                    }
                );

                this.$router("/admin/order");
            }
        )
    },
    this.cancelPayPal = async () => {
        this.$confirm('페이팔 결제 내역이 취소됩니다. 취소된 내역은 복구될 수 없습니다. 환불할까요?').then(
            async ()=>{
                await this.callOrderUpdaterApi(
                    'App\\Actions\\Payment\\PayPalRefundAction',
                    {
                        od_id : this.orderId
                    }
                )

                this.getOrder();
            }
        );
    },
    this.callOrderUpdaterApi = async (class_name, parameters) => {
        this.errors = {};
        return await axios.post("/api/store/order-updater",
            {
                class_name: class_name,//'App\\Actions\\Order\\OrderUpdateItemDelete',
                parameters: parameters
            }).then(res => {

            this.$message.success(res.data.message);

            return res.data;

        }, err => {
            const res = err.response;
            this.errors = res.data;
        });
    },
    this.setOrderMember = async (member) => {

        this.$confirm('주문자의 명의가 변경되면 가격, 배송료, 할인 등에 영향을 줍니다. 변경할까요?').then(
            async ()=>{
                await this.callOrderUpdaterApi(
                    'App\\Actions\\Order\\OrderUpdateMemberChange',
                    {
                        od_id : this.orderId,
                        mb_id : member.mb_id
                    }
                )

                this.getOrder();
            }
        );

    },
    this.applyCouponToOrder = async () => {
        await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateCouponApply',
            {
                od_id: this.order.od_id,
                coupon_id: this.order.coupon_id
            }
        );

        this.getOrder();
    },
    this.updateOrderPayment = async () => {
        let data = await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdatePayment',
            {
                od_id: this.order.od_id,
                od_receipt_point: this.order.od_receipt_point,
                od_refund_cash: this.order.od_refund_cash,
                od_refund_amount: this.order.od_refund_amount,
                od_dc_amount: this.order.od_dc_amount,
                od_receipt_bank: this.order.od_receipt_bank,
                od_receipt_card: this.order.od_receipt_card,
                od_bank_time: this.order.od_bank_time,
                od_card_time: this.order.od_card_time,
                od_send_cost: this.order.od_send_cost,
                od_exdelivery: this.order.od_exdelivery,
            }
        );

        this.getOrder();

    },
    this.updateAdminMessage = async () => {
        await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateAdminMemo',
            {
                od_id: this.orderId,
                od_comment: this.order.od_comment
            }
        )
        this.getOrder();
    },
    this.deleteOrderCommentLog = async (log) => {
        await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateAdminMemoLogDelete',
            {
                od_id: this.orderId,
                uid: log.uid
            }
        );

        this.getOrder();
    },
    this.delayOrder = async () => {
        // 보류상태가 해제 또는 설정됩니다.
        let data = await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateDelay',
            {
                od_id: this.orderId,
                status: !this.order.od_delay
            }
        );

        

        this.getOrder();
    },
    this.cancelCoupon = async (issue) => {
        await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateCouponUnset',
            {
                od_id: this.orderId,
                issue_id: issue.id
            }
        );

        this.getOrder();
    },
    this.cancelKcpPayment = async () => {
        await this.callOrderUpdaterApi(
            'App\\Actions\\Order\\OrderUpdateCancelKcpPayment',
            {
                od_id: this.orderId
            }
        );

        this.getOrder();
    },
    this.updateShipment = async () => {
        await this.callOrderUpdaterApi(
            "App\\Actions\\Order\\OrderUpdateShipmentData",
            {
                od_id: this.orderId,
                od_name: this.order.od_name,
                od_email: this.order.od_email,
                od_hp: this.order.od_hp,
                od_tel: this.order.od_tel,
                od_zipcode: this.order.od_zipcode,
                od_addr1: this.order.od_addr1,
                od_addr2: this.order.od_addr2,
                od_memo: this.order.od_memo,
                dl_id: this.order.dl_id,
                od_b_country: this.order.od_b_country,
                od_b_name: this.order.od_b_name,
                od_b_hp: this.order.od_b_hp,
                od_b_tel: this.order.od_b_tel,
                od_b_zipcode: this.order.od_b_zipcode,
                od_b_addr1: this.order.od_b_addr1,
                od_b_addr2: this.order.od_b_addr2,
                od_delivery_memo: this.order.od_delivery_memo,
            }
        );

        this.getOrder();
    },
    this.changeStatusItem = async (status) => {
        let items = this.checkSelectedItems();

        for (let i in items) {
            await this.callOrderUpdaterApi(
                'App\\Actions\\Order\\OrderUpdateItemEdit',
                {
                    od_id: this.order.od_id,
                    ct_id: items[i].ct_id,
                    ct_status: status,
                    ct_amount: items[i].ct_amount,
                    ct_qty: items[i].ct_qty
                }
            )
        }
        this.getOrder();
    }
    this.getOrder = async () => {
        await axios.get("/api/store/order/" + this.order.od_id)
            .then(res => {
                this.order = res.data;
                this.orderId = this.order.od_id
            })
        return Promise.resolve(this)
    }
    this.addItem = async (optionNumber,quantity) => {
      await this.callOrderUpdaterApi(
          'App\\Actions\\Order\\OrderUpdateItemAdd',
          {
              od_id: this.order.od_id,
              op_id: optionNumber,
              qty: quantity
          })
    }
}

export { OrderModel }

export default {
    namespaced: true,
    actions:{
        async testAction(){

        }
    }
}
