<script>
    import EvTagSelect from "./EvTagSelect";
    export default {
        name: "EvSelect",
        extends : EvTagSelect,
        props: {
            single : {
                default : true
            }
        }
    }
</script>
