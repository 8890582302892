<template>
  <div>
    <grid-template v-model="items"
                   cell-type="value"
                   :field-labels="{
                    op_id : '가격번호',
                    it_id : '상품번호',
                    op_itname : '상품명',
                    op_name : '단위',
                    total_count : '판매수량',
                   }"
                   pagination
                   :parent-data="this"
                   no-checker
    >
      <template v-slot:cell:op_id="{row}">
        <el-button @click="()=>{createPurchaseItem(row)}">{{ row.op_id }}</el-button>
      </template>
    </grid-template>
  </div>
</template>

<script>
import AxiosHelper from "@/evans/plugins/AxiosHelper";
import GridTemplate from "@/evans/templates/GridTemplate";
import {postPurchaseItems} from "@/apis/purchase";

export default {
  name: "SellItemList",
  components: {GridTemplate},
  mixins: [AxiosHelper],
  data() {
    return {
      uri: '/api/store/me/orders/any/items',
      query: {
        sales_date: 'today'
      }
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async createPurchaseItem(row) {

      const res = await postPurchaseItems({
        op_id : row.op_id,
        qty : row.total_count,
        options : row.selected_options
      });

      this.$emit('posted', res.data);
    }
  }
}
</script>

<style scoped>

</style>
