<template>
    <div>
        <table class='w-full bg-white'>
            <colgroup>
                <col width="50px">
                <col>
                <col width="100px">
                <col width="100px">
                <col width="90px">
                <col width="60px">
            </colgroup>
            <thead class="bg-gray-900 text-white">
                <tr class="h-12">
                    <th>번호</th>
                    <th>제목</th>
                    <th>아이디</th>
                    <th>고객명</th>
                    <th>날짜</th>
                    <th>조회수</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="fixednotice in fixedNoticeData">
                    <tr class="h-16 font-extrabold"
                        :class="{
                            'border-b hover:bg-gray-200': !toggleList.includes('fixed_' + fixednotice.wr_id),
                            'bg-gray-100':toggleList.includes('fixed_' + fixednotice.wr_id)
                        }"
                        @click="showContents('fixed_' + fixednotice.wr_id)"
                    >
                        <td>
                            {{fixednotice.wr_id}}
                        </td>
                        <td class="text-left hover:underline cursor-pointer">
                            {{fixednotice.wr_subject}}
                        </td>
                        <td>
                            {{fixednotice.mb_id}}
                        </td>
                        <td>
                            {{fixednotice.wr_name}}
                        </td>
                        <td>
                            {{fixednotice.wr_datetime}}
                        </td>
                        <td>
                            {{fixednotice.wr_hit}}
                        </td>
                    </tr>
                    <tr v-if="toggleList.includes('fixed_' + fixednotice.wr_id)"
                        class="h-16 text-left border-b"
                        :class="{
                            'bg-gray-100':toggleList.includes('fixed_' + fixednotice.wr_id)
                        }"
                    >
                        <td class="p-4"
                            colspan="6"
                            v-html="fixednotice.wr_content"
                        ></td>
                    </tr>
                </template>
                <template v-for="notice in noticeData">
                    <tr class="h-16"
                        :class="{
                            'border-b hover:bg-gray-200': !toggleList.includes(notice.wr_id),
                            'bg-gray-100': toggleList.includes(notice.wr_id)
                        }"
                        @click="showContents(notice.wr_id)"
                    >
                        <td>
                            {{notice.wr_id}}
                        </td>
                        <td class="text-left hover:underline cursor-pointer">
                            {{notice.wr_subject}}
                        </td>
                        <td>
                            {{notice.mb_id}}
                        </td>
                        <td>
                            {{notice.wr_name}}
                        </td>
                        <td>
                            {{notice.wr_datetime}}
                        </td>
                        <td>
                            <!-- 조회수 카운트는 어떻게 가져와야하는지 확인 필요 -->
                        </td>
                    </tr>
                    <tr v-if="toggleList.includes(notice.wr_id)"
                        class="h-16 text-left border-b"
                        :class="{
                            'bg-gray-100': toggleList.includes(notice.wr_id)
                        }"
                    >
                        <td class="p-4"
                            colspan="6"
                            v-html="notice.wr_content"
                        ></td>
                    </tr>
                </template>
                <tr v-if="noticeData.length === 0">
                    <td colspan="6"
                        class="p-8 w-full text-center text-2xl text-gray-500"
                    >
                        검색결과가 없습니다.
                    </td>
                </tr>
            </tbody>
        </table>
        <ev-pagination :total="pageInfo.total"
                       :per-page="pageInfo.per_page"
                       :scope="7"
                       :value="pageInfo.current_page"
                       @input="setPage"
        />
    </div>
</template>

<script>
    import axios from "../../axiosConfig";

    export default {
        name: "NoticeList",

        components: {
        },

        data(){
            return {
                fixedNoticeData: [],
                noticeData: [],
                pageInfo: {},
                toggleList: []
            }
        },

        methods: {
            setPage(num) {
              axios.get(`/api/store/board/sinsa_notice?sort=wr_num&order=asc&page=${num}`)
                  .then(res => {
                    this.noticeData = res.data.data;
                    this.pageInfo = res.data;
                  })
            },

            showContents(id) {
                let idx = this.toggleList.indexOf(id);

                if (idx > -1) {
                    this.toggleList.splice(idx, 1)
                } else {
                    this.toggleList.push(id)
                }
            }
        },

        created() {
          axios.get('/api/store/board/sinsa_notice?sort=wr_num&order=asc')
              .then(res => {
                this.fixedNoticeData = res.data.notices;
                this.noticeData = res.data.data;
                this.pageInfo = res.data;
              })
        }
    }
</script>