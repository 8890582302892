import {messenger} from "@/evans/plugins/EvToast";

export default {
  namespaced: true,
  state: {
    workStartHour: 9, // 출근 시간. 0 ~ 23까지 입력
    workStartMinute: 0, // 출근 분. 0 ~ 59까지 입력
    pos:{
      printerIp: '', // 프린터 공유를 위한 아이피 설정
      deleteOrderedCart:false, // 주문완료된 상품 내역 삭제 여부
      searchableOrder:false, // 정산 주문내역 검색 여부
      managerPassword:null // 관리자 비밀번호 설정
    }
  },
  getters: {
    pos: state => state.pos,
    workStartHour: state => state.workStartHour,
    workStartMinute: state => state.workStartMinute,
  },
  mutations: {
    /**
     *
     * @param state
     * @param value
     */
    setConfig(state, value)
    {
      state.pos = value
    },
    /**
     * 출근시간 설정
     * @param state
     * @param payload {{hour: Number, minute: Number}} 출근시간
     */
    setWorkStartTime(state, payload){

      if(payload < 0 || payload.hour > 23){
        messenger.$message({type: "error", message: `출근 시간은 0시 부터 23시 사이로 설정해주세요.`})
        return;
      }
      if(payload < 0 || payload.minute > 59){
        messenger.$message({type: "error", message: `출근 분은 0분 부터 59분 사이로 설정해주세요.`})
        return;
      }

      state.workStartHour = payload.hour
      state.workStartMinute = payload.minute
      messenger.$message({type: "success", message: `출근 시간이 ${state.workStartHour}시 ${state.workStartMinute}분 으로 설정되었습니다.`})
    }
  }
}