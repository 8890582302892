<template>
  <div class="bg-white flex flex-1 justify-center my-2">
    <span v-show="isRemainLeftPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200"
          @click="setPage(1)">처음</span>
    <span v-show="isRemainLeftPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200"
          @click="goBefore">이전</span>

    <span v-show="isRemainLeftPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200">...</span>

    <span v-for="num in getScopedPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200"
          :class="getActiveClass(num)"
          @click="setPage(num)">{{ num }}</span>

    <span v-show="isRemainRightPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200">...</span>

    <span v-show="isRemainRightPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer hover:bg-gray-200" @click="goAfter">다음</span>
    <span v-show="isRemainRightPager"
          class="bg-white bg-gray-100 py-2 px-4 cursor-pointer border-l-0 hover:bg-gray-200"
          @click="setPage(getPageCount)">끝</span>
  </div>
</template>
<script>
export default {
  name: 'EvPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 15
    },
    scope: {
      type: Number,
      default: 3
    },
    value: {
      type: Number,
      default: 1
    },
    // 페이지 포커스(현재페이지)일 경우 클래스
    activeClasses: {
      type: Array,
      default: function () {
        return ['font-bold', 'bg-main-300', 'text-black']
      }
    },
    // 페이징시, 페이지 상단으로 스크롤 기능 사용 여부
    scrollTop: {
      type: Boolean,
      default: false
    },
    // 페이지 상단 이동시, behavior option (https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior)
    scrollTopBehavior: {
      type: String,
      default: 'smooth',
    },
    // 페이지 상단 이동 시, 클릭 후 이동 전 딜레이 변수
    scrollTopDelay: {
      type: Number,
      default: 250
    }
  },
  computed: {
    isRemainLeftPager() {
      return this.getStartPager > 1;
    },
    isRemainRightPager() {
      return this.getLastPager < this.getPageCount;
    },
    getStartPager() {
      return this.value - Math.ceil((this.scope - 1) / 2);
    },
    getLastPager() {
      return this.value + Math.ceil((this.scope - 1) / 2);
    },
    getPageCount() {
      return Math.ceil(this.total / this.perPage)
    },
    getScopedPager() {
      let cnt = 0;
      let pagers = [];
      if (this.getStartPager < 1) {
        while (cnt < this.scope) {
          pagers.push(1 + cnt);
          cnt++;
        }
      } else if (this.getLastPager > this.getPageCount) {
        while (cnt < this.scope) {
          pagers.push(this.getPageCount - cnt);
          cnt++;
        }
      } else {
        while (cnt < this.scope) {
          pagers.push(this.getStartPager + cnt);
          cnt++;
        }
      }

      return pagers.filter(i => {
        return i <= this.getPageCount && i > 0;
      }).sort((a, b) => {
        return a - b;
      });
    }
  },
  methods: {
    goBefore() {
      this.$emit('input', this.value - 1);
      this.scrollToTop()
    },
    goAfter() {
      this.$emit('input', this.value + 1);
      this.scrollToTop()
    },
    setPage(num) {
      this.$emit('input', num);
      this.scrollToTop()
    },
    getActiveClass(num) {
      return this.value === num ? this.activeClasses : [];
    },
    scrollToTop() {
      if (this.scrollTop === true) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: this.scrollTopBehavior,
          });
        }, this.scrollTopDelay)
      }
    }
  }
}
</script>
