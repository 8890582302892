<template>
  <div>
    <div class="tab-list flex items-center">
      <div v-for="tab in tabList"
           class="tab-item cursor-pointer"
           :class="[
             tabStyle,
             tabSelected === tab.value? selectedStyle : hoverStyle,
           ]"
           :style="{
             transitionDuration: transitionDuration+'ms',
             transitionProperty: transitionProperty
           }"
           @click="setTab(tab.value)"
      >
        {{ tab.name }}
      </div>
    </div>
    <div v-if="separator"
         class="tab-separator"
    >
      <slot name="separator">
        <hr class="my-2">
      </slot>
    </div>
    <slot>

    </slot>
  </div>
</template>

<script>
  export default {
    name: "simpleTab",

    model: {
      prop: 'tabSelected',
      event: 'change'
    },

    props: {
      // 탭 목록. [{name: String, value: String}] 형태여야 한다.
      tabSelected: {type: String },
      tabList: {
        type: Array,
        require: true,
        validator: function (value) {
          for(const item of value){
            if(!item.hasOwnProperty('name')) return false
            if(!item.hasOwnProperty('value')) return false
            if(typeof item.value !== 'string') return false
            if(typeof item.name !== 'string') return false
          }
          return true
        }
      },
      separator: {type: Boolean, default: true },
      tabStyle: {default: 'mr-2 p-2 rounded'},
      transitionProperty: {default: 'all'},
      transitionDuration: {default: 250},
      hoverStyle: {default: 'hover:bg-gray-400'},
      selectedStyle: {default: 'bg-blue-600 font-extrabold text-white'}
    },

    methods: {
      setTab(tabValue){
        this.selected = tabValue
        this.$emit('change', this.selected)
      }
    },

  }
</script>

<style scoped>

</style>