<template>
  <div class="py-4">
    <order-invoice :order-id="purchaseId"></order-invoice>
  </div>
</template>

<script>
import OrderInvoiceItemsTable from "@/controls/purchase/OrderInvoiceItemsTable";
import axios from "@/axiosConfig";
import EvansBox from "@/components/EvansBox";
import OrderInvoice from "@/controls/purchase/OrderInvoice";

export default {
  name: "PurchaseDetail",
  components: {OrderInvoice, EvansBox, OrderInvoiceItemsTable},
  props:{
    purchaseId: null
  },
  data(){
    return {
      uri : '/api/store/me/purchases/',
      order : {}
    }
  },
  methods:{
    async getPurchase(){
      const response = await axios.get('/api/store/me/purchases/' + this.purchaseId);
      this.order = response.data;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
