<template>
  <el-tag :type="getType" size="mini" class="order-status">
    {{ getStatus }}
  </el-tag>
</template>

<script>
export default {
  name: "OrderItemStatusTag",
  props: {
    status: String,
    lang: null
  },
  computed: {
    getStatus() {
      return this.status;
    },
    getType() {
      if (this.status === '준비' || this.status === '주문') {
        return 'warning';
      }
      if(this.status === '배송'){
        return 'info';
      }
      if( this.status === '완료'){
        return 'success';
      }
      if (this.status === '취소' || this.status === '반품' || this.status === '완전취소') {
        return 'danger';
      }
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>
.order-status {
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
</style>
