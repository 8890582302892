<template>
    <div class="image-cropper" :style="cropStyle">
        <img ref="source" :src="src" :style="getImgStyle" class="img" @load="calHeight()">
    </div>
</template>

<script>
    export default {
        name: "RatioImg",
        props: {
            src: String,
            width : {
                type : Number,
                default : 0
            },
            ratio : {
                type: Array,
                default : ()=>{ return [3,4] }
            },
            vertical : {
                type : String,
                default : 'middle'
            },
            closeable : Boolean
        },
        computed: {
            getWidth(){
                return (this.getHeight * this.ratio[0]) / this.ratio[1];
            },
            getHeight(){
                var w = this.width;
                if(this.imgWidth){
                    w = this.imgWidth;
                }
                return (w * this.ratio[1]) / this.ratio[0];
            },
            getMargin(){
                return this.getHeight / 2;
            },
            cropStyle(){
                return { width : this.getWidth + "px" , height: this.getHeight + 'px' };
            },
            getImgStyle()
            {
                if(this.vertical === 'middle'){
                    return { marginTop : (this.imgHeight - this.getHeight) / 2 * -1 +'px'}
                }
                if(this.vertical === 'top'){
                    return {}
                }
                if(this.vertical === 'bottom'){
                    return { marginTop : (this.imgHeight - this.getHeight) * -1 +'px'}
                }
            }
        },
        data() {
            return {
                imgWidth: 0,
                imgHeight: 0,
                loaded : false
            }
        },
        methods : {
            handler(){
                this.$emit("closed");
            },
            calHeight(){
                this.$nextTick(()=>{
                    this.imgHeight = this.$el.children[0].height;
                });

                this.$emit('loaded', this.$refs['source']);
            }
        },
        mounted()
        {
            if(!this.width){
                this.imgWidth = this.$el.parentNode.clientWidth;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .image-cropper {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        .img {
            width: 100%;
            height: auto;
        }
    }

</style>
