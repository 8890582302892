<template>
  <div class="w-full py-2 border-solid border-gray-600 border-2 rounded mt-2 bg-white rounded">

    <!-- 상품 목록 -->
    <div class="w-full">
      <table class="w-full border-gray-600">
        <thead>
        <tr class="border-b border-gray-600" style="height: 44px;">
          <th class="w-1/12">번호</th>
          <th class="w-5/12">상품명</th>
          <th class="w-1/12">단위</th>
          <th class="w-1/12">수량</th>
          <th class="w-1/12">단가</th>
          <th class="w-1/12">소계</th>
          <th class="w-1/12">삭제</th>
        </tr>
        </thead>
        <tbody v-if="items.length >= 1">
        <template v-for="(item, index) in items">
          <tr :key="item.ct_id"
              class="border-b border-solid border-gray-600"
          >
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td>
              {{ item.op_itname }}
            </td>
            <td>
              {{ item.op_name }}
            </td>
            <td>
              <div class="w-full flex">
                <ev-button class="w-8"
                           :disabled="item.ct_qty <= 1 || item.ct_status === '취소' || item.ct_status === '반품'"
                           @click="decreaseQuantity(item)"
                >-
                </ev-button>
                <ev-input class="w-full"
                          :value="item.ct_qty"
                          :disabled="item.ct_status === '취소' || item.ct_status === '반품'"
                          @change="(val) => modifyQuantity(item, val)"
                />
                <ev-button class="w-8"
                           :disabled="item.ct_status === '취소' || item.ct_status === '반품'"
                           @click="increaseQuantity(item)"
                >+
                </ev-button>
              </div>
            </td>
            <td> <!-- 정가보다 할인된 가격일 경우 색상 변화 -->

              <div class="text-right">
                {{ item.ct_amount | formatInt }}
              </div>

            </td>
            <td class="text-right">
              {{ (item.ct_amount * item.ct_qty) | formatInt }}
            </td>
            <td class="text-center">
              <ev-button type="danger" @click="deleteItem(item.ct_id)"
              >삭제
              </ev-button>
            </td>
          </tr>
          <tr v-if="item.children.length > 0" v-for="child in getSelectedItem(item)" :key="child.ct_id"
              class="bg-gray-300" style="height: 44px;"
          >
            <td class="text-center">
              {{ child.op_id }}
            </td>
            <td>
              {{ child.op_itname }}
            </td>
            <td>
              {{ child.op_name }}
            </td>
            <td class="text-center">
              {{ child.ct_qty }}
            </td>
            <td class="text-right"> <!-- 정가보다 할인된 가격일 경우 색상 변화 -->
              {{ child.ct_amount | formatInt }}
            </td>
            <td class="text-right">
              {{ ((child.ct_amount - child.discount_amount) * child.ct_qty) | formatInt }}
            </td>
            <td class="text-center">
              선택구성
            </td>
          </tr>
        </template>
        </tbody>
        <tbody v-else>
        <tr class="p-4 w-full">
          <td colspan="8" class="p-4 text-gray-500">
            등록된 상품이 없습니다.
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 상품 목록 종료 -->

    <!-- 하단 일괄 컨트롤 -->
    <div class="w-full mt-2 px-2 text-right flex justify-end ">
      <div class="flex items-center">
        <div class="w-24 inline-block text-xl font-bold pr-1"> 합계</div>
        <span class="w-full text-2xl text-red-600 text-left font-extrabold"> {{ getTotal | formatInt }} </span>
      </div>
    </div>
    <!-- 하단 컨트롤 종료 -->
    <div class="p-1 ">
      <ev-button class="rounded mr-2" @click="getItems">장바구니 불러오기</ev-button>
      <ev-button class="rounded" @click="postPurchaseOrder">발주하기</ev-button>
    </div>
  </div>
</template>

<script>
import {deletePurchase, getPurchaseItems, postPurchaseOrder, putPurchaseItem} from "@/apis/purchase";

let debouncer;

export default {
  name: "PurchaseCartList",
  data() {
    return {
      items: [],
      loaded:false
    }
  },
  filters: {
    formatInt(val) {
      let string = parseInt(val).toLocaleString();
      let result = '';
      for (const i in string) {
        if (!i % 3) {
          result += string[i] + ','
        } else {
          result += string[i]
        }
      }
      return result;
    }
  },
  computed: {
    getTotal() {
      if (this.isEmptyCart) {
        return 0;
      }
      return this.items.map(item => item.ct_qty * item.ct_amount).reduce((pre, cur) => {
        return pre + cur
      })
    },
    isEmptyCart() {
      return !this.items || this.items.length === 0;
    },
    getCartKey() {
      if (this.isEmptyCart) return null;
      return this.items[0]['on_uid'];
    }
  },
  methods: {
    /**
     * 세트상품 중에 선택한 옵션만 표기 해 줍시다.
     * @param item
     * @return {*}
     */
    getSelectedItem(item) {
      return item.children.filter((child) => {
        return item.selected_options.includes(child.is_matched);
      })
    },
    decreaseQuantity(item) {
      if (item.ct_qty <= 1) {
        return;
      }
      this.modifyQuantity(item, item.ct_qty - 1)
    },
    increaseQuantity(item) {
      this.modifyQuantity(item, item.ct_qty + 1)
    },
    modifyQuantity(item, quantity) {

      item.ct_qty = quantity;

      clearTimeout(debouncer);
      debouncer = setTimeout(async () => {
        await putPurchaseItem(item.ct_id, {ct_qty: quantity});
        await this.getItems();
      }, 500);
    },
    async deleteItem(id) {

      await deletePurchase(id)
      await this.getItems();
    },
    async getItems() {
      const response = await getPurchaseItems();
      if (response.data && response.data.data) {
        this.items = response.data.data;
        if( this.items.length === 0 && this.loaded){
          this.$alert('발주 장바구니가 비어 있습니다.');
        }
        this.loaded = true;
      }
    },
    async postPurchaseOrder() {
      let res;
      try {
        await this.$confirm("이대로 발주하시겠습니까?");
        res = await postPurchaseOrder(this.getCartKey)
        this.$message.success('주문이 완료 되었습니다.');
        this.$router.push('/purchase/'+res.data.od_id+'/show');
      } catch (e) {
        res = e.response;

      }

      if (res && res.data && res.data.message) {
        this.$message.success(res.data.message)
      }
    }
  },
  mounted() {
    this.getItems();

  }
}
</script>

<style scoped>

</style>
