<template>
    <div class="rounded p-2 shadow-md border" v-if="hasSelectives">
        <h6> 옵션선택 </h6>
        <div v-for=" (options, key) in getSelectives">
            <ev-select v-model="selectedOptions[key]" :key="key + 'option'"
                       :single="false"
                       :max="product.it_voluntary_qty"
                       :options="options"
                       :label-key="price.product.it_name"
                       value-key="uid"
                       :placeholder=" options[0].selective + ' 선택'" style="width: 100%;"
            >
                <template v-slot:option="prop">
                    <select-option-label :item="prop.item"></select-option-label>
                </template>
                <template v-slot:result="prop">
                    <select-option-label :item="prop.item"></select-option-label>
                </template>
            </ev-select>
        </div>
        <div v-for="(options, i) in selectedOptions">
            <h6> 수량입력 </h6>
            <div class="py-2 border-t border-b my-2 " :class="signalClass">
                총 합계가 {{ product.it_voluntary_qty }} 개 이어야 합니다. ( 현재 : {{ totalOptionQty }} 개 )
            </div>
            <div v-for="option in options">
                <div class="flex justify-between items-center ">
                    <div>
                        {{ option.son_name }}
                    </div>
                    <div>
                        <ev-input v-model="option.qty" :key="option.op_id + 'qty'" class="w-2/3"></ev-input>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <ev-button @click="send()">완료</ev-button>
        </div>
    </div>
</template>

<script>
    import SetProduct from "@/evans/controls/cartables/SetProduct";

    export default {
        name: "VoluntarySetProduct",
        extends : SetProduct,
        computed:{
            isSelectedDone() {
                return false;
            },
            totalOptionQty() {
                let sum = 0;
                for (let i in this.selectedOptions){
                    for (let z in this.selectedOptions[i]){
                        sum += parseInt( this.selectedOptions[i][z].qty ? this.selectedOptions[i][z].qty : 0 );
                    }
                }
                return sum;
            },
            signalClass() {
                let isOver = this.totalOptionQty > parseInt(this.product.it_voluntary_qty);
                if ( isOver ){
                    return ['text-red-600' ];
                } else if( this.totalOptionQty === parseInt(this.product.it_voluntary_qty)  ){
                    return ['text-green-600'];
                }
                return [];
            }
        },
        methods:{
            setupParams(){
                this.params.op_id = this.price.op_id;
                this.params.it_id = this.price.it_id;
                this.params.is_gift = this.isGift;
                this.params.options = [];
                for (let i in this.selectedOptions){
                    for (let z in this.selectedOptions[i]){
                        this.params.options.push({uid:this.selectedOptions[i][z].uid,qty:this.selectedOptions[i][z].qty});
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
