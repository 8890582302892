<template>
    <div class="flex items-start" style="min-width: 300px">
        <div style="width: 40px; height: 40px; overflow: hidden;" class="mr-2">
            <img alt="thumb" :src="item.price.product.thumb_image" style="max-width: 40px">
        </div>
        <div class="text-left">
            {{ item.price.product.it_name +
            '['+item.price.op_name+']' }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectOptionLabel",
        props:{
            item : Object
        }
    }
</script>

<style scoped>

</style>
