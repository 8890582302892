<template>
  <div id="app" class="bg-gray-200" style="min-height: 100vh;">

    <!-- 글로벌 axios progress bar -->
    <vue-progress-bar />

    <!-- GNB 네비게이션 영역 -->
    <div id="nav" class="py-2 px-4 bg-gray-200">
      <div class="container max-w-screen-lg mx-auto">
        <div class="flex justify-between ">
          <div class="p-2 mr-2 w-1/5">
            <b>Candleworks POS</b> <span>{{ $store.getters['user/user'].mb_emp_name }}({{ $store.getters['user/user'].store_id }})</span>
          </div>
          <div class=" ml-auto mr-0 text-right w-4/5">
            <template v-if="$store.getters['user/user']">
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/sales'>판매</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/adjust'>정산</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/purchase'>발주</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/inventory'>재고</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/customers'>CS관리</router-link>
              <router-link v-if="this.$config.get('hasSubCustomer')" tag="button" class="bg-gray-500 p-2 mx-2 rounded"
                           to='/member'>고객</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/address'>주소록</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/notice'>공지사항</router-link>
              <router-link tag="button" class="bg-gray-500 p-2 mx-2 rounded" to='/config'>환경설정</router-link>
              <button class="bg-gray-500 p-2 mx-2 rounded"
                      @click="$store.dispatch('user/logout')"
              >로그아웃</button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- GNB 네비게이션 영역 종료 -->

    <!-- 콘텐츠 영역 -->
    <div class="container max-w-screen-lg mx-auto pb-4">
      <router-view/>
    </div>
    <!-- 콘텐츠 영역 종료 -->
  </div>
</template>

<script>
  import gnb from "@/GlobalMenus"

  export default {
    name: 'App',
    data() {
      return {
        gnb: gnb
      }
    },
  }
</script>

<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
    min-width: 1024px;
  }

  #nav {
    text-align: left;
    /*padding: 15px;*/

    button {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-active {
        background-color: #42b983;
        color: white;
      }
    }
  }
</style>
