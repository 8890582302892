<template>
  <div class="header text-left py-2 border-b-4 border-gray-600">
    <div class="header-title flex">
      <div class="text-3xl">
        <slot name="header"/>
      </div>
      <div class="mr-0 ml-auto">
        <slot name="control"/>
      </div>
    </div>
    <div class="header-subtitle ">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "posHeader"
  }
</script>

<style scoped>

</style>