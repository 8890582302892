<template>
    <ev-remote-select
            ref="selector"
            label-key="it_name"
            value-key="it_id"
            api-url="/api/v1/resource/product?it_name="
            :value="getValue"
            @input="handler"
            :single="!multiple"
            :default-options="getDefaultOption"
    ></ev-remote-select>
</template>

<script>
    import EvRemoteSelect from "../components/EvRemoteSelect";

    export default {
        name: "EvProductSelector",
        components: {EvRemoteSelect},
        data() {
            return {
                mutate: {}
            }
        },
        props: {
            value: null,
            valueField: {
                default: 'it_id'
            },
            labelField: {
                default: 'it_name'
            },
            multiple: {
                type: Boolean
            },
            default: {
                type: Object
            }
        },
        methods: {
            handler(val) {
                this.mutate = val;

                if (!val) {
                    this.$emit('input', null);
                    return;
                }
                if (this.multiple) {
                    let data = val.map((item) => {
                        let temp = {};
                        temp[this.valueField] = item[this.valueField];
                        temp[this.labelField] = item[this.labelField];

                        return temp;
                    });
                    this.$emit('input', data);

                    return;
                }

                this.$emit('input', val);

            }
        },
        computed: {
            getDefaultOption() {
                if (!this.default || typeof this.default !== 'object') {
                    return [];
                }
                if (this.default.it_id && this.default.it_name) {
                    return [this.default]
                }

                if (this.default.product && this.default.product.it_name) {
                    return [{it_id: this.default.it_id, it_name: this.default.product.it_name}];
                }
                return [{it_id: '', it_name: ''}]
            },
            getValue() {

                if (this.default && this.default.it_id && this.default.product && this.default.product.it_name) {
                    this.$nextTick(() => {
                        this.$refs['selector'].pushRemoteOption({
                            it_id: this.default.it_id,
                            it_name: this.default.product.it_name
                        });
                    });

                    return {it_id: this.default.it_id, it_name: this.default.product.it_name};
                }

                if (this.mutate && this.mutate.hasOwnProperty(this.valueField)) {
                    return this.mutate;
                }

                return this.value;
            }
        }
    }
</script>

<style scoped>

</style>
