import Vue from 'vue'
import VueRouter from 'vue-router'

import Notice from "../views/Notice"
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import PurchaseList from "@/views/Purchase/PurchaseList";
import PurchaseDetail from "@/views/Purchase/PurchaseDetail";
import PurchaseCreate from "@/views/Purchase/PurchaseCreate";
import PurchaseReceive from "@/views/Purchase/PurchaseReceive";
import InventoryList from "@/views/Inventory/InventoryList";
import InventoryReport from "@/views/Inventory/InventoryReport";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/notice',
    },
    {
        path: '/notice',
        name: 'Notice',
        component: Notice
    },
    {
        path: '/test',
        name: 'Test',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/sales',
        name: 'Sales',
        component: () => import('../views/Sales'),
    },
    {
        path: '/adjust',
        name: 'Adjust',
        component: () => import('../views/Adjust')
    },
    {
        path: '/order',
        name: 'Order',
        component: () => import('../views/Order')
    },
    {
        path: '/stock',
        name: 'Stock',
        component: () => import('../views/Stock')
    },
    {
        path: '/customers',
        name: 'Customers',
        component: () => import('../views/Customers')
    },
    {
        path: '/address',
        name: 'Address',
        component: () => import('../views/Address')
    },
    {
        path: '/label',
        name: 'Label',
        component: () => import('../views/Label')
    },
    {
      path: '/config',
      name: 'Config',
      component: () => import('../views/Config')
    },
    {
        path: '/member',
        name: "MemberPage",
        component: () => import('../views/MemberPage')
    },
    {
        path: '/purchase',
        component: () => import('../views/Purchase'),
        children: [
            {
                path:'',
                name:'PurchaseCreate',
                component: PurchaseCreate
            },
            {
                path: 'list',
                name:'PurchaseList',
                component: PurchaseList
            },
            {
                path: ':purchaseId/show',
                name:'PurchaseDetail',
                component: PurchaseDetail,
                props: true
            },
            {
                path: ':orderId/receive',
                name:'PurchaseReceive',
                component: PurchaseReceive,
                props: true
            },
        ]
    },
    {
        path: '/inventory',
        component: () => import('../views/Inventory.vue'),
        children: [
            {
                path:'',
                name:'InventoryList',
                component: InventoryList
            },
            {
                path: 'report',
                name:'InventoryReport',
                component: InventoryReport,
                props: true
            }
        ]
    }
]

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    // base: process.env.BASE_URL,
    publicPath: '/',
    routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

router.beforeEach((to, from, next) => {


    // 판매 페이지를 벗어나는 것이며, 판매 수정 상태라면 장바구니 상태를 초기화
    if (from.name === 'Sales' && store.state.sales.mode === 'edit') {
        store.commit('sales/clearCart')
    }

    // 로그인 체크. Login 페이지를 제외하고 페이지 이동 시 모두 로그인 체크
    if (to.name !== 'Login' && store.state.user.user === null) {

        store.dispatch('user/hello')
            .then(() => {
                next()
            })
            .catch(() => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })

    } else {
        next()
    }

})

export default router
