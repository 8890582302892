import axios from "../../axiosConfig";
import { cloneDeep } from 'lodash'

/**
 * 이송대기목록 로컬 storage
 * 재고(stock) 페이지에서 주로 처리하므로 stock.js
 */
export default {
  namespaced: true,
  state: {
    cart: [],
  },
  getters: {
    itemList: state => {
      return state.cart
    },
    getItemByOpId: state => op_id => {
      return state.cart.find(item => item.op_id === op_id) || null
    }
  },
  mutations: {
    /**
     * 카트에 아이템을 담음
     * @param state
     * @param payload {{
     *   it_id: Number,
     *   it_name: String,
     *   op_id: Number,
     *   op_name: String,
     *   op_qty: Number,
     * }}
     */
    addItemToCart(state, payload){
      
      if(payload.op_qty === 0) return;

      // 재고 체크

      // 옵션이 중복될 경우 수량을 늘려줌
      let currentIdx =  state.cart.findIndex(insertedItem => {
        return insertedItem.op_id === payload.op_id
      })
      if(currentIdx !== -1) {
        state.cart[currentIdx].op_qty = Number(state.cart[currentIdx].op_qty + payload.op_qty)
        return;
      }

      // insert
      payload.tr_status = "이송대기"
      state.cart.push(_.cloneDeep(payload))
    },
    /**
     * 카트에 담긴 아이템의 수량을 변경
     * @param state
     * @param payload {{
     *   op_id: Number, qty: Number
     * }}
     */
    editItemQuantityInCart(state, payload){

    },
    /**
     * 카트에 담긴 아이템 삭제
     * @param state
     * @param payload {{
     *   op_id: Number
     * }}
     */
    removeItemInCart(state, payload){
      let currentIdx =  state.cart.findIndex(insertedItem => insertedItem.op_id === payload.op_id)
      if(currentIdx === -1) return

      state.cart.splice(currentIdx, 1)
    },
    /**
     * 카트를 초기화
     * @param state
     */
    clearCart(state){
      state.cart = []
    }
  },
  actions: {
    /**
     * 카트에 담긴 아이템들을 발주 주문(transfer-order)에 담는다.
     * @param state
     * @param commit
     * @param payload {{
     *   trans_id: {String},
     *   to: {Number},
     *   from: {Number}
     * }}
     */
    async insertItemsOnTransferOrder({state,commit},payload){
      let success = [] // 성공 목록
      let failed = [] // 실패 목록
      let orderResponse = await axios.get('/api/store/transfer-order/'+payload.trans_id)// 중복 체크를 위해 trans 정보를 가져온다.
      let promiseArray = [] // Promise 목록

      // 반복
      for(const item of state.cart){

        // 이미 추가된 아이템 정보가 있는지 확인
        let currentItem = orderResponse.data.items.find(insertedItem => { insertedItem.op_id == item.op_id })
        
        if(currentItem){
          // 이미 추가된 아이템이 있다면, 수량을 늘려줌
          promiseArray.push(axios.put('/api/store/transfer-order-item/'+currentItem.uid,{
            op_qty: Number(currentItem.op_qty) + Number(item.op_qty),
          })
            // 처리 결과를 담음
            .then(res => {
              
              success.push(item)
              return res
            })
            .catch(error => {
              console.error(error)
              failed.push(item)
              return error
            }))

        } else {
          // 새로운 아이템 등록
          promiseArray.push(axios.post('/api/store/transfer-order-item',{
            trans_id: payload.trans_id,
            to: payload.to,
            from: payload.from,
            op_id: item.op_id,
            op_qty: item.op_qty,
            memo: item.memo
          })
            // 처리 결과를 담음
            .then(res => {
              
              success.push(item)
              return res
            })
            .catch(error => {
              console.error(error)
              failed.push(item)
              return error
            }))
        }
      }

      // promiseArray dispatch
      return Promise.allSettled(promiseArray)
        .then(result => {
          // 처리 결과 리턴
          return {
            message: 'done',
            success: success,
            failed: failed
          }
        })
        .finally(() => {
          commit('clearCart')
        })
    }
  },
}

/**
 * item Mockup
 * {
 *  it_id
 *  it_name
 *  op_id
 *  op_name
 *  op_qty
 * }
 */