var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ev-form',{staticClass:"my-4 p-2 bg-white rounded",attrs:{"errors":_vm.errors},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('ev-form-field',{attrs:{"field":"it_name","label":"상품명"}}),_c('ev-form-field',{attrs:{"field":"it_id","label":"상품번호"}}),_c('ev-form-field',{attrs:{"field":"op_id","label":"단위"}},[_c('ev-price-selector',{attrs:{"value-key":"op_id"},model:{value:(_vm.query.op_id),callback:function ($$v) {_vm.$set(_vm.query, "op_id", $$v)},expression:"query.op_id"}})],1),_c('ev-form-field',{attrs:{"field":"reportBy","label":"정렬기준"}},[_c('ev-select',{attrs:{"options":[
          {value:'it_id', label:'상품기준'},
          {value:'op_id', label:'단위기준'},
      ]},model:{value:(_vm.query.reportBy),callback:function ($$v) {_vm.$set(_vm.query, "reportBy", $$v)},expression:"query.reportBy"}})],1),_c('el-button',{on:{"click":_vm.getItems}},[_vm._v("검색")])],1),_c('div',{staticClass:"my-4"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":()=>{ _vm.openDialog = !_vm.openDialog }}},[_vm._v("새로 등록하기")])],1),_c('el-dialog',{attrs:{"visible":_vm.openDialog},on:{"update:visible":function($event){_vm.openDialog=$event}}},[_c('inventory-form',{on:{"created":_vm.createdItem}})],1),_c('grid-template',{attrs:{"parent-data":this,"pagination":"","footable":"","field-width":{
                    it_id : 100,
                    unit : 120,
                    unit_weight : 100,
                    stock : 100
                 },"field-labels":{
      it_id : '상품번호',
      it_name : '상품명',
      unit : '단위',
      unit_weight : '재고량',
      stock : '무게합계',
      memo: '메모',
      edit_qty : '조정수량'
    },"cell-type":"value"},scopedSlots:_vm._u([{key:"cell:edit_qty",fn:function({row,value,handler}){return [_c('div',{staticClass:"w-24"},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"manual","value":row.__is_selected && parseInt(value) > 0}},[_c('div',[_c('el-input',{attrs:{"type":"textarea","value":row.edited_memo,"placeholder":"재고위치나 사유를 입력하세요."},on:{"input":(val)=>{_vm.updateEditedMemo(val,row)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":()=>{_vm.postEditedInventory(row)}}},[_vm._v("조정하기")]),_c('el-button',{attrs:{"type":"warning"},on:{"click":()=>{_vm.cancelEditedInventory(row)}}},[_vm._v("취소")])],1),_c('el-input',{attrs:{"slot":"reference","value":value},on:{"input":(v)=>{_vm.updateEditQty(v,handler)}},slot:"reference"})],1)],1)]}},{key:"cell:it_id",fn:function({value}){return [_c('div',[_c('el-button',{on:{"click":()=>{_vm.getPrices(value)}}},[_vm._v(_vm._s(value))])],1)]}},{key:"cell:unit_weight",fn:function({value,row}){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(row.stock / value,'usd'))+" ")])]}},{key:"cell:stock",fn:function({value, row}){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatInt")(value))+" ")])]}},(_vm.query.reportBy === 'op_id')?{key:"foot:unit",fn:function(){return [_vm._v(" "+_vm._s(_vm.getDefaultUnit)+" ")]},proxy:true}:null,(_vm.query.reportBy === 'op_id')?{key:"foot:unit_weight",fn:function(){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatInt")(_vm.getTotalCount))+" ")])]},proxy:true}:null,(_vm.query.reportBy === 'op_id')?{key:"foot:stock",fn:function(){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatInt")(_vm.getTotal))+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }