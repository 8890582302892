<template>
    <div class="bg-white rounded shadow mt-2">
        <div class="border-b border-main-600 p-2" v-if="title">
            <slot name="title">
                <h6 class="font-bold uppercase">{{title}}</h6>
            </slot>
        </div>
        <div :class="bodyClasses">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'EvCard',
        props:{
            title : String,
            bodyClasses : [Array, String]
        }
    }
</script>
