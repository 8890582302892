<template>
  <div>
    <table class="table-auto w-full bg-white">
      <thead>
      <tr>
        <th v-if="value.length === 0">
          <h4 class="p-8">데이터가 없습니다.</h4>
        </th>
        <th v-if="!hiddenChecker" class="px-2 py-2 border-b border-l first:border-l-0">
          <ev-check-box type="checkbox" v-model="toggleAll"/>
        </th>
        <th v-for="head in getColumns " class="px-2 py-2 border-b border-l first:border-l-0 ">
          <div class="flex items-center justify-around">
            <slot :name="'header:'+head" :head="head">{{ getHead(head) }}</slot>
            <span v-if="isSortableHead(head)" class="flex flex-col  border rounded">
                            <i @click="setSortQuery(head, 'asc')" class="fa fa-arrow-up hover:bg-gray-100 border-b p-1"
                               style="width: 22px; height : 22px"
                               :class="(isActiveSortHeader(head, 'asc')) ? 'bg-gray-300':''"></i>
                            <i @click="setSortQuery(head, 'desc')" class="fa fa-arrow-down hover:bg-gray-100 p-1"
                               style="width: 22px; height : 22px"
                               :class="(isActiveSortHeader(head, 'desc')) ? 'bg-gray-300':''"></i>
                        </span>
          </div>
        </th>
        <th v-if="hasActionSlot" class="px-2 py-2 border-b border-l first:border-l-0"> @</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item, idx) in value">
        <tr :class="getRowClasses(value[idx])" :key="idx + _uid">
          <td class="border-b px-2 py-2 text-14" v-if="!noChecker"
              :style="(Object.keys(fieldWidth).length) ? {width : '40px'} : {} ">
            <ev-check-box type="checkbox" :checked="value[idx]['__is_selected']" @input="toggle(idx)"/>
          </td>
          <template v-for="head in getColumns">
            <td class="border-b px-2 py-2 text-14 border-l first:border-l-0"
                :style="(Object.keys(fieldWidth).includes(head)) ? {width : fieldWidth[head] + 'px'} : {} ">
              <slot :name="'cell:'+head" :row="item" :field="head" :value="getCell(item, head)"
                    :index="idx"
                    :handler="(val)=>{ updateCell(idx,head,val) }">
                <template v-if=" Object.keys(fieldType).includes(head) ">
                  <ev-input v-if="fieldType[head] === 'input'"
                            :value="getCell(item, head)" @input="(val)=>{updateCell(idx,head,val)}"/>
                  <ev-datepicker v-if="fieldType[head] === 'datepicker'" v-model="value[idx][head]"/>
                  <ev-textarea v-if="fieldType[head] === 'textarea'" :value="getCell(item, head)"
                               @input="(val)=>{updateCell(idx,head,val)}"/>
                  <span v-if="fieldType[head] === 'value'"> {{ getCell(item, head) }} </span>

                </template>
                <template v-else>
                  <ev-input v-if="cellType === 'input'" :value="getCell(item, head)" class="w-full"
                            @input="(val)=>{updateCell(idx,head,val)}"/>
                  <span v-if="cellType === 'value'"> {{ getCell(item, head) }} </span>
                </template>
              </slot>
              <div v-if="hasErrorCell(item, head)" v-for="error in getErrorCell(item, head)"
                   class="absolute p-2 shadow bg-white text-red-400 hover:opacity-75 z-10 ">{{ error }}
              </div>
            </td>
          </template>
          <slot name="action" :index="idx" :item="item"></slot>
        </tr>
        <slot name="children" :index="idx" :item="item"></slot>
      </template>
      </tbody>
      <slot name="footer" v-if="footable">
        <tfoot>
        <tr class="border-t">
          <td class="border px-2 py-2" v-if="!hiddenChecker">
            &nbsp;
          </td>
          <template v-for="head in getColumns">
            <td class="border px-2 py-2">
              <slot :name="'foot:'+head" :field="head"></slot>
            </td>
          </template>
        </tr>
        </tfoot>
      </slot>
    </table>
    <slot name="controller" v-if="controlable">
      <div class="my-2 ml-4">
        <ev-button class="border border-blue-600 rounded-tl rounded-bl" type="primary" @click="addBlank">
          등록
        </ev-button>
        <ev-button class="border border-blue-600" type="primary" @click="submit">
          수정
        </ev-button>
        <ev-button class="border border-blue-600 rounded-tr rounded-br" type="primary" @click="destroy">
          삭제
        </ev-button>
      </div>
    </slot>

    <slot name="pagination" v-if="pagination">
      <ev-pagination class="border p-2"
                     :total="getParentData.total"
                     :per-page="getParentData.perPage"
                     :value="getParentData.page"
                     @input="updatePage"
                     :scope="5"></ev-pagination>
    </slot>

  </div>
</template>

<script>
import EvInput from "../components/EvInput";
import EvButton from "../components/EvButton";
import EvCheckBox from "../components/EvCheckBox";
import EvPagination from "../components/EvPagination";
import EvAlert from "../components/EvAlert";

export default {
  name: "GridTemplate",
  components: {EvAlert, EvPagination, EvCheckBox, EvButton, EvInput},
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    fieldLabels: {
      type: Object,
      default() {
        return {};
      }
    },
    fieldType: {
      type: Object,
      default() {
        return {};
      }
    },
    excepts: {
      type: Array,
      default() {
        return [];
      }
    },
    cellType: {
      default: 'input'
    },
    cellWidth: {
      default: 'auto'
    },
    errors: {
      default() {
        return {}
      }
    },
    noChecker: {
      type: Boolean,
      default: false
    },
    footable: {
      type: Boolean,
      default: false
    },
    fieldWidth: {
      type: Object,
      default() {
        return {}
      }, required: false
    },
    controlable: {type: Boolean, default: false},
    pagination: {type: Boolean, default: false},
    parentData: {
      type: Object
    },
    // 조건에 따라 tr 클래스를 추가합니다.
    rowStyler: {
      type: Function,
      default: null,
      required: false
    },
    sortables: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      toggleAll: false,
      selectedItems: {},

    }
  },
  watch: {
    toggleAll(val) {
      if (val) {
        this.value.map((item, idx) => {
          this.setItemSelected(idx);
        });
      } else {
        this.value.map((item, idx) => {
          item.__is_selected = false;
        });
      }
    }
  },
  computed: {
    getParent() {
      if (this.parentData) {
        return this.parentData;
      }
      return this.$parent;
    },
    getColumns() {
      if (this.getItems.length === 0) {
        return [];
      }

      if (Object.keys(this.fieldLabels).length > 0) {
        return Object.keys(this.fieldLabels);
      }
      return Object.keys(this.getItems[0]);
    },
    getItems() {
      return this.value.map((item) => {
        let availableKeys = Object.keys(item).filter(key => {
          return !this.excepts.includes(key)
        });

        let data = {};
        availableKeys.map(k => {
          data[k] = item[k];
        });

        return data;
      });
    },
    hasActionSlot() {

      return this.$scopedSlots.hasOwnProperty('action') && this.value.length !== 0;
    },
    hiddenChecker() {
      return this.noChecker || this.value.length === 0
    },
    getParentData() {
      if (this.parentData) {
        return this.parentData;
      } else {
        return this.getParent.$data;
      }
    }
  },
  methods: {
    getColumnValueLength(head) {
      let values = this.getItems.map((item) => {
        if (!item[head]) {
          return 0;
        }
        if (typeof item[head] !== 'string') {
          return item[head].toString().length;
        }
        return item[head].length;
      });
      return (Math.max(...values) * 8.5);
    },
    getInputStyle(head) {
      if (this.cellWidth !== 'auto') {
        return {width: this.cellWidth}
      }
      let num = this.getColumnValueLength(head);
      if (!num) {
        return {};
      }
      return {width: num + 52 + 'px'}
    },
    getHead(head) {
      if (this.fieldLabels.hasOwnProperty(head)) {
        return this.fieldLabels[head];
      }
      return head;
    },
    getCell(item, head) {
      return item[head];
    },
    updateCell(index, head, value) {

      let temps = this.value;
      if( typeof temps[index][head] === 'undefined'){
        this.$set(this.value[index], head, value);
        console.log('undefined');
      }
      temps[index][head] = value;
      this.clearError(index, head);
      this.setItemSelected(index);
      this.$emit('input', temps);
    },
    clearError(index, head) {
      if( typeof this.getParent.cleanError === 'function')
        this.getParent.cleanError(this.getItems[index][this.primaryKey], head);
    },
    addBlank() {
      if( typeof this.getParent.addBlankForm === 'function')
        this.getParent.addBlankForm();
    },
    unsetItemSelected(idx) {
      this.$set(this.value[idx], '__is_selected', false);
    },
    setItemSelected(idx) {
      this.$set(this.value[idx], '__is_selected', true);
    },
    toggle(idx) {
      if (this.isSelectedRow(idx)) {
        this.unsetItemSelected(idx);
      } else {
        this.setItemSelected(idx);
      }
    },
    isSelectedRow(idx) {
      return this.value && this.value[idx] && this.value[idx].__is_selected;
    },
    hasErrorCell(item, head) {
      return item[this.primaryKey] && this.errors[item[this.primaryKey]] && this.errors[item[this.primaryKey]][head];
    },
    getErrorCell(item, head) {
      if (!this.hasErrorCell(item, head)) {
        return [];
      }
      return this.errors[item[this.primaryKey]][head];
    },
    updatePage(val) {

      this.getParent.setPage(val);
    },
    hasChildren(value) {
      return false;
    },
    getRowClasses(row) {

      let classes = [];

      if (row['__is_selected'] === true) {
        classes.push(['bg-blue-200']);
      }

      if (this.rowStyler && typeof this.rowStyler === 'function') {
        classes.push(this.rowStyler(row));
      }

      return classes;
    },
    setSortQuery(field, direction) {
      this.getParent.query.sort = field
      this.getParent.query.order = direction;
      this.getParent.getItems();
    },
    isSortableHead(head) {
      return this.sortables.filter((sort) => {
        return head === sort;
      }).length > 0;
    },
    isActiveSortHeader(field, order) {
      return this.getParent.query.sort === field && this.getParent.query.order === order;
    },
    submit() {
      this.getParent.confirmSubmit();
    },
    destroy() {
      this.getParent.confirmDelete();
    }
  }
}
</script>

