<template>
  <div class="home h-full" style="min-height: 900px;">
    <ev-input v-model="test" placeholder="검사"></ev-input>
    <cart-create-helper v-model="op_id" @selected="val => op_id2 = val"></cart-create-helper>
<!--    <daum-zip-finder v-model="zipcode"></daum-zip-finder>-->
    <ev-button @click="login()">토큰획득</ev-button>
    <EvCustomerSelector v-model="customer"></EvCustomerSelector>

    <ev-card title="order test">
      <div>
        <ev-input v-model="od_id"></ev-input>
        <ev-button @click="getOrder()">주문 갱신</ev-button>
        <ev-button @click="order.delayOrder()">보류</ev-button>
        <ev-input v-model="op_id2"></ev-input>
        <ev-button @click="order.addItem(op_id2,1)">추가</ev-button>
      </div>
    </ev-card>

    <ev-card title="category selector test">
      <category-selector v-model="category"
                         @selected="changeCategory"
      />
    </ev-card>

    <ev-card title="component-test">
      <input v-model="testObject.input" />
      <ev-input v-model="testObject.input" />
    </ev-card>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/controls/HelloWorld.vue'
import CartCreateHelper from "../evans/controls/CartCreateHelper";
// import DaumZipFinder from "@/evans/controls/DaumZipFinder";
import EvCustomerSelector from "@/evans/controls/EvCustomerSelector";

import { OrderModel } from "../store/modules/order";
import CategorySelector from "../components/categorySelector";

export default {
  name: 'Home',
  components: {
    CategorySelector,
    EvCustomerSelector,
    // DaumZipFinder,
    CartCreateHelper,
    // HelloWorld
  },
  data(){
    return {
      test : '안녕하세요?',
      op_id: null,
      op_id2: null,
      zipcode: null,
      auth: {},
      customer: null,
      od_id: '200619-003313',
      order: new OrderModel,
      category: null,
      testObject: {
        input: '',
        checked: false
      },
    }
  },
  methods: {
    async login() {
      const res = await this.axios.post('/api/store/auth', {
        mb_id : 'apgujong',
        mb_password : 'dkqrnwjd12!@'
      });
      let token = `Bearer ${res.data.token}`;

      this.axios.defaults.headers.common['Authorization'] = token;
    },
    async getOrder(){
      this.order.order = {od_id: this.od_id }
      this.order.getOrder()
        .then(() => {}
      // await this.order.delayOrder()
      )
    },
    changeCategory(val){

    }
  },
  watch: {
    customer(newCustomer){

    },
    category(newCategory){

    }
  }
}
</script>
