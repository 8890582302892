var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white rounded shadow"},[_c('div',{staticClass:"p-2 text-lg"},[_vm._v(" "+_vm._s(_vm.order.od_id)+" | "+_vm._s(_vm.order.od_time)+" | "+_vm._s(_vm.order.od_comment)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.order.od_status === '완료'),expression:"order.od_status === '완료'"}],staticClass:"my-2 p-2 bg-red-600 rounded text-white shadow"},[_vm._v(" 이미 입고가 된 주문입니다. 주의 하세요. ")]),_c('div',{staticClass:"rounded shadow-lg my-4 bg-white p-0"},[_c('h3',{staticClass:"p-2 font-bold text-xl"},[_vm._v("일괄입고 내역 "+_vm._s((_vm.orderId) ? '#' + _vm.orderId : '')+" ")]),_c('grid-template',{attrs:{"errors":_vm.errors,"primary-key":"ct_id","field-width":{
    ct_status: 80,
    ct_qty: 100,
    op_name: 120,
    in_qty: 100,
    memo:120
                 },"field-labels":{
    ct_status: '상태',
    op_itname: '상품명',
    op_name: '단위',
    ct_qty:'주문수량',
    in_qty:'입고수량',
    memo:'위치'
  },"cell-type":"value","pagination":""},scopedSlots:_vm._u([{key:"cell:in_qty",fn:function({value,handler}){return [_c('ev-input',{staticClass:"w-full",attrs:{"value":value},on:{"input":handler}})]}},{key:"cell:memo",fn:function({value,handler}){return [_c('ev-input',{staticClass:"w-full",attrs:{"value":value},on:{"input":handler}})]}}]),model:{value:(_vm.order.items),callback:function ($$v) {_vm.$set(_vm.order, "items", $$v)},expression:"order.items"}})],1),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.receiving}},[_vm._v("입고하기")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }