<template>
    <transition name="ease-fade">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "EaseFadeTransition"
    }
</script>

<style>
    .ease-fade-item {
        transition: all 1s;
        display: inline-block;
    }
    .ease-fade-enter, .ease-fade-leave-to
        /* .ease-fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateX(30px);
    }
    .ease-fade-leave-active {
        position: absolute;
    }
</style>
