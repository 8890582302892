<template>
  <div class="text-left bg-white inline-block" style="max-width: 360px; z-index: 10;">
    <price-simple-finder class="w-full" ref="price-finder" :value="value" @input="inputHandler" @selected="prepare"
                         cached></price-simple-finder>
    <div v-if="getProductCartable">
      <component ref="cartable"
                 @complete="setBack"
                 v-if="getProductCartable"
                 :is="getProductCartable"
                 :price="price"
                 :is-gift="isGift"
      />
    </div>

  </div>
</template>

<script>
import PriceSimpleFinder from "./PriceSimpleFinder";
import Product from "@/evans/controls/cartables/Product";
import SetProduct from "@/evans/controls/cartables/SetProduct";
import VoluntarySetProduct from "@/evans/controls/cartables/VoluntarySetProduct";

/**
 * 장바구니에 상품을 담는 컴포넌트
 * 상품마다 보내야할 규격이 다르므로 이를 맞춰주는 역할을 합니다.
 *
 * <Product>
 * 일반상품 예시
 * https://candleworks.co.kr/shop/item.php?it_id=2375
 * {"it_id":"2375","op_id":415890,"options":[],"qty":1}
 *
 * <SetProduct>
 * 세트상품 예시
 * https://candleworks.co.kr/shop/item.php?it_id=1083
 * {"it_id":"1083","op_id":115770,"options":[49,52],"qty":1}
 *
 * <CustomLabelProduct>
 * 커스텀 라벨 예시
 * https://candleworks.co.kr/shop/item.php?it_id=2361
 * {"it_id":"2361","op_id":415817,"options":[],"qty":1,"label_message":{"item1":"디퓨저 (Reed Diffuser)","item2":"Reed Diffuser","item3":"DIFFUSER","item4":"SIMPLE ELEGANCE & AESTHETICS","label_name":"GARDEN SWEET PEA ","label_info":"RIPE PEAR, PEACH, RASPBERRIES\nSWEET PEA BLOSSOM, MUGUET\nGREEN LEAVES, FREESIA, ROSEWOOD","label_note":"FLORAL","label_message":"TO LAUGH\nYOURSELF IS\nLOVE YOURSELF"}}
 *
 * <VoluntarySetProduct>
 * 옵션수량 임의 선택품 수량 조절 세트 상품 예시
 * https://candleworks.co.kr/shop/item.php?it_id=1994
 * {"op_id":415429,"qty":1,"options":[{"uid":1937,"qty":2},{"uid":1652,"qty":3},{"uid":1649,"qty":3},{"uid":1651,"qty":2}]}
 * 이 상품은 product.it_voluntary_qty 에 총 합 수량이 표시됨. 이 값이 있는 상품은 임의선택정수량조정세트
 */
export default {
  name: "CartCreateHelper",
  components: {VoluntarySetProduct, SetProduct, Product, PriceSimpleFinder},
  props: {
    value: null,
    isGift: null
  },
  data() {
    return {
      price: null
    }
  },
  computed: {
    getProductCartable() {
      if (!this.price) {
        return null;
      }
      return this.price.product.cartable ?? 'Product'
    }
  },
  methods: {
    inputHandler(value) {
      this.price = null;
      this.$emit('input', value);
      this.$emit('selected', value);
    },
    prepare(price) {
      this.price = price;
    },
    setBack(res) {
      this.price = null;
      this.$emit('input', null);
      this.$refs['price-finder'].focusInput();
    }
  }
}
</script>

<style scoped>

</style>
