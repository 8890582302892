<!--suppress ALL -->
<template>
  <div class="relative inline-block">
    <ev-input ref="searchInput"
              :value="value"
              @input="inputHandler"
              @keyup.esc="escapeHandler"
              @keyup.up="startNavigatorFromBack"
              @keyup.down="startNavigator"
              @keyup.enter="submitNavigator"
              placeholder="상품검색"
              class="w-full"
    ></ev-input>
    <div ref="optionsMenu" class="flex-col absolute z-10 shadow border bg-white " :class="getDisplay" style="max-height: 70vh; overflow: scroll">
      <div v-for="option in options"
           @click="()=>{ sendId(option) }"
           class="p-2 first:border-t-0 border-t whitespace-no-wrap cursor-pointer hover:bg-gray-200"
           :class="traveler.op_id === option.op_id ? 'bg-green-600 text-white' : ''"
      >
        {{ option.product.it_name }} [ {{ option.op_name }} ] - <span class="font-bold" :class="(option.discount_price) ? 'line-through' : '' ">{{ getCustomerPrice(option) }}원</span>
        <span v-if="option.discount_price" class="font-bold"> {{ getDiscountedPrice(option) }} 원 </span>
      </div>
    </div>
    <input ref="navigator" class="absolute outline-none" style="z-index: -1"
           @keyup.esc="escHandler"
           @keyup.down="downHandler"
           @keyup.up="upHandler"
           @keyup.enter="enterHandler">
  </div>
</template>

<script>
import axios from '@/axiosConfig'

/**
 * 가격정보 검색 후 선택된 가격정보 아이디를 전달합니다.
 * @emit input v-model 용
 * @emit submit
 * @emit change 아이디 값을 전달하는 것 이외에 추가적인 로직에 사용하세요.
 */
export default {
  name: "PriceSimpleFinder",
  props: {
    /**
     * @model
     */
    value: null,
    cached : {
      type: Boolean
    }
  },
  data() {
    return {
      keyword: '', // 입력된 키워드
      rawData: [],
      options: [],
      loading: false,
      travelerNo: 1,
      traveler: {},
      showOptions: false,
      availableArrow: true,
      isBounce: false, // 키워드 입력 중(키워드 입력중이라면 ajax 실행 유보)
      bounceInterval: 500
    }
  },
  computed: {

    getDisplay() {
      return (this.showOptions) ? 'flex' : 'hidden';
    },
    getMenu() {
      return this.$refs.optionsMenu;
    },
    getButton() {
      return this.$refs.searchInput.$el;
    },
    getOptions() {
      if(this.rawData.length > 0){

        return this.rawData.filter((item)=>{
          return item.product.it_name.toLowerCase().includes(this.keyword)
        });
      }
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    startNavigatorFromBack(e) {
      if (this.options.length === 0) {
        return;
      }
      e.target.blur();
      this.$refs['navigator'].focus();
      this.travelerNo = this.options.length;
      this.traveler = this.options[this.travelerNo - 1];
      this.showOptions = true;
    },
    startNavigator(e) {
      if (this.options.length === 0) {
        return;
      }
      e.target.blur();
      this.$refs['navigator'].focus();
      this.travelerNo = 1;
      this.traveler = this.options[this.travelerNo - 1];

      e.stopPropagation();
      this.showOptions = true;
      this.availableArrow = false;
      setTimeout(() => {
        this.availableArrow = true;
      }, 300);
    },
    downHandler(e) {

      if (!this.options.length || !this.availableArrow) {
        return;
      }

      if (this.travelerNo <= this.options.length) {
        this.travelerNo = this.travelerNo + 1;
      }
      if (this.travelerNo > this.options.length) {
        this.travelerNo = 1;
      }
      this.traveler = this.options[this.travelerNo - 1];
      e.stopPropagation();
    },
    upHandler(e) {
      if (!this.options.length || !this.availableArrow) {
        return;
      }

      if (this.travelerNo > 1) {
        this.travelerNo = this.travelerNo - 1;
      } else {
        this.travelerNo = this.options.length;
      }

      this.traveler = this.options[this.travelerNo - 1];
    },
    enterHandler(e) {
      if (!this.traveler) {
        return this.$refs['searchInput'].$el.focus();
      }
      this.sendId(this.traveler);

      this.traveler = {};
      this.travelerNo = 0;

    },
    escHandler(e) {
      e.target.blur();
      this.$refs['searchInput'].$el.focus();
      this.travelerNo = 0;
      this.traveler = {};
    },
    inputHandler(value) {

      if (this.keyword === value) {

        this.$emit('input', value);
        return;
      } else {
        this.searchOpId(value);
      }
      this.showOptions = true;
      this.$emit('input', value);
    },
    getPriceIds() {
      axios.get('/api/v1/resource/price?it_use=1&op_state=true&it_name=' + this.keyword).then(
          (res) => {
            this.options = res.data.data;
            this.loading = false;
            this.showOptions = true;
          }
      )
    },
    searchOpId(value) {
      this.keyword = value;
      if (!this.keyword) {
        return;
      }

      if(this.cached){
        this.options = this.rawData.filter((item)=>{
          return !item.product || !item.product.it_name ? false : item.product.it_name.toLowerCase().includes(this.keyword);
        });
        return ;
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getPriceIds();
      }, this.bounceInterval);

    },
    sendId(option) {

      this.$emit('input', option.op_id);
      this.$emit('selected', option);
      this.$emit('change', option.op_id);
      this.$emit('submit', option.op_id);
      this.showOptions = false;

    },
    checkParent(t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true;
        }
        t = t.parentNode;
      }
      return false;
    },
    documentClickEventHandler(e) {

      var target = (e && e.target);
      if (this.checkParent(target, this.getMenu)) {
        // 메뉴 안에 있는 타켓은 아무 것도 하지 않습니다.
        return;
      }
      // 메뉴 밖에 있는 요소 중 버튼이라면 ?!
      if (this.checkParent(target, this.getButton)) {
        // 토글합니다.
        this.toggleOptions()

        return;
      }
      // 버튼외에 다른 요소라면 팝업을 가립니다.
      this.showOptions = false;

    },
    submitNavigator(e) {
      this.$emit('submit', e)
    },
    focusInput() {
      this.$refs['searchInput'].$el.focus();
    },
    getCustomerPriceRaw(option) {
      const member = this.$store.getters["sales/member"];
      if(!member || !member.my_group){
        return option.op_price1
      }else if(member.my_group =='도매'){
        return option.op_price2;
      }else{
        return option.op_price3;
      }
    },
    getCustomerPrice(option) {
      const member = this.$store.getters["sales/member"];
      if(!member || !member.my_group){
        return new Intl.NumberFormat().format(option.op_price1)
      }else if(member.my_group =='도매'){
        return '[도매]' + new Intl.NumberFormat().format(option.op_price2);
      }else{
        return new Intl.NumberFormat().format(option.op_price3);
      }
    },
    async getAllProductList()
    {
      let res = await axios.get("/api/store/product-list");
      this.rawData = res.data.data;

    },
    escapeHandler() {
      this.showOptions = false;
    },
    getDiscountedPrice(option) {
      let customerPriceRaw = this.getCustomerPriceRaw(option);
      const price = customerPriceRaw - (customerPriceRaw * option.discount_price.discount / 100);
      return new Intl.NumberFormat().format( price );
    }
  },
  mounted() {
    if(this.cached){
      this.getAllProductList()
    }
    document.addEventListener('click', this.documentClickEventHandler);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClickEventHandler);
  }
}
</script>

<style scoped>

</style>
