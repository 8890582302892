export default [
    {
        name: '판매',
        to: '/sales'
    },
    {
        name: '정산',
        to: '/adjust'
    },
    {
        name: '발주',
        to: '/order'
    },
    {
        name: '재고',
        to: '/stock'
    },
    {
        name: 'CS관리',
        to: '/customers'
    },
    {
        name: '고객',
        to: '/member'
    },
    {
        name: '주소록',
        to: '/address'
    },
    {
        name: '공지사항',
        to: '/notice'
    },
    {
        name: '환경설정',
        to: '/config'
    }
]