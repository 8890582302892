var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ev-form',{staticClass:"my-4 p-2 bg-white rounded",attrs:{"errors":_vm.errors},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('ev-form-field',{staticClass:"w-1/4",attrs:{"field":"it_name","label":"상품명"}}),_c('ev-form-field',{staticClass:"w-1/4",attrs:{"field":"op_id","label":"단위"},scopedSlots:_vm._u([{key:"default",fn:function({value,handler}){return [_c('ev-price-selector',{attrs:{"value-key":"op_id","value":value},on:{"input":handler}})]}}])}),_c('ev-form-field',{staticClass:"w-1/4",attrs:{"field":"memo","label":"메모"}}),_c('ev-button',{on:{"click":_vm.getItems}},[_vm._v("검색")])],1),_c('grid-template',{attrs:{"errors":_vm.errors,"field-labels":{
        created_at: '등록일시',
        it_id : '상품번호',
        it_name: '상품명',
        unit: '단위',
        in_qty: '입고수량',
        out_qty: '출고수량',
        type: '유형',
        memo: '메모'
      },"cell-type":"value","pagination":""},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('div',{staticClass:"my-4"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":()=>{ _vm.openDialog = !_vm.openDialog }}},[_vm._v("새로 등록하기")])],1),_c('el-dialog',{attrs:{"visible":_vm.openDialog},on:{"update:visible":function($event){_vm.openDialog=$event}}},[_c('inventory-form',{on:{"created":_vm.createdItem}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }