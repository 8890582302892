import axios from "@/axiosConfig";
import {getSerializedQuery} from "@/apis/ApiHelper";

/**
 * 발주주문 메모수정
 * @param {int} purchase_id
 * @param {Object} data
 * @param {string?} data.od_comment
 * @param {string?} data.od_delivery_memo
 * @param {string?} data.od_memo
 */
export async function updatePurchaseData(purchase_id, data)
{
    return await axios.put(`/api/store/me/purchases/${purchase_id}`, data);
}
/**
 * 발주대기 내역 가져오기
 * @return {Promise<AxiosResponse<any>>}
 */
export async function getPurchaseItems(query) {
    return await axios.get("/api/store/me/purchases/me/items?" + getSerializedQuery(query));
}

/**
 * 발주상품 등록하기
 * @return {Promise<AxiosResponse<any>>}
 */
export async function postPurchaseItems(params) {
    return await axios.post("/api/store/me/purchases/me/items", params);
}

/**
 * 발주상품 수량 수정
 * @return {Promise<AxiosResponse<any>>}
 */
export async function putPurchaseItem(id, params) {
    return await axios.put('/api/store/me/purchases/me/items/' + id, params)
}

/**
 * 발주상품 삭제
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
export async function deletePurchase(id) {
    return await axios.delete('/api/store/me/purchases/me/items/' + id);
}

export async function postPurchaseOrder(refKey) {
    return await axios.post('/api/store/me/purchases', {on_uid: refKey})
}

export async function confirmReceiveOrder(orderId){
    return await axios.put('/api/store/me/purchases/' + orderId, {confirm:orderId})
}
