<template>
  <ev-card class="p-2">
    <div class="inline-block pr-2">
      <ev-button class="rounded" @click="()=>{ showTodaySales = !showTodaySales }">금일판매상품보기</ev-button>
    </div>
    <form class="w-1/3 inline-block items-center"
          @submit.prevent="barcodeInput">
      <div class="w-1/5 inline-block">
        <label>상품스캔</label>
      </div>
      <div class="w-3/5 inline-block">
        <ev-input v-model="barcode"/>
      </div>
      <ev-button class="w-1/5" @click="barcodeInput">등록</ev-button>
    </form>
    <div class="w-1/3 inline-block pl-2">
      <div class="w-1/5 inline-block">
        <label>상품검색</label>
      </div>
      <purchase-item-helper class="w-4/5" v-model="op_id" :is-gift="isGift"
                            @created="()=>{ $emit('created', true) }"
      />
    </div>
    <sell-item-list class="m-4" v-if="showTodaySales" @posted="()=>{ $emit('created', true) }"></sell-item-list>
  </ev-card>
</template>

<script>
  import CartCreateHelper from "../../evans/controls/CartCreateHelper";
  import PurchaseItemHelper from "@/controls/purchase/PurchaseItemHelper";
  import SellItemList from "@/controls/purchase/SellItemList";
  import {postPurchaseItems} from "@/apis/purchase";

  export default {
    name: "AddPurchaseCart",
    components: {
      SellItemList,
      PurchaseItemHelper,
      CartCreateHelper
    },
    data(){
      return {
        barcode: null,
        op_id: null,
        isGift: false,
        showTodaySales: false
      }
    },
    methods: {
      /**
       * 폼이 submit 될 때 바코드 인식 동작
       */
      async barcodeInput(){
        this.barcode = this.barcode.replace(/[^0-9]/g,'');

        await postPurchaseItems({op_id:this.barcode,qty:1,options:[]});
        this.$emit('created', true)
        this.barcode = null;
      }
    }
  }
</script>

<style scoped>

</style>
