<template>
  <div class="text-left bg-white inline-block" style="max-width: 360px; z-index: 10;">
    <price-simple-finder class="w-full" ref="price-finder" :value="value" @input="inputHandler" @selected="prepare"
                         cached></price-simple-finder>
    <div v-if="getProductCartable">
      <component ref="cartable"
                 @complete="setBack"
                 v-if="getProductCartable"
                 :is="getProductCartable"
                 :price="price"
                 :is-gift="isGift"
                 :handler="PostPurchaseItem"
      />
    </div>

  </div>
</template>

<script>
import CartCreateHelper from "@/evans/controls/CartCreateHelper";
import {postPurchaseItems} from "@/apis/purchase";

export default {
  name: "PurchaseItemHelper",
  extends: CartCreateHelper,
  methods: {
    async PostPurchaseItem({params}) {

      try {
        const res = await postPurchaseItems(params);
        this.$emit('created', true);
      } catch (e) {
        this.$emit('failed', true);
      }

      this.$emit('input', '')
      this.price = null;
    }
  }
}
</script>
